import React, { useState, useEffect } from "react";
import Select from "react-select";
import styles from "../styles/PlayerComparison.module.css";
import { getNationalityName } from "../helpers/NationalityMapping";

export function MultiSelectPlayerFilters({ label, onPlayerSelected }) {
  // We'll store the options as arrays of { value, label }
  const [teamOptions, setTeamOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([
    { value: "C", label: "C" },
    { value: "L", label: "L" },
    { value: "R", label: "R" },
    { value: "D", label: "D" },
  ]);

  // The user’s selected values:
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedNationalities, setSelectedNationalities] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);

  // The list of players, and a selected player
  const [players, setPlayers] = useState([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState("");

  // Loading states
  const [isLoadingPlayers, setIsLoadingPlayers] = useState(false);

  useEffect(() => {
    // Fetch teams & nationalities once on mount
    getData();
  }, []);

  async function getData() {
    try {
      const teamResp = await fetch("/api/teams/");
      const teamData = await teamResp.json();
      const mappedTeams = teamData.map((t) => ({
        value: t.abb,
        label: t.name,
      }));
      setTeamOptions(mappedTeams);

      const natResp = await fetch("/api/nationalities/");
      const natData = await natResp.json();
      // Convert abbreviations to full name using getNationalityName
      const mappedNats = natData.map((abbr) => ({
        value: abbr,
        label: getNationalityName(abbr),
      }));
      setNationalityOptions(mappedNats);
    } catch (err) {
      console.error("Error fetching filter data:", err);
    }
  }

  // Whenever filters change, fetch players
  useEffect(() => {
    let url = "/api/players/";
    const params = new URLSearchParams();

    selectedTeams.forEach((teamObj) => params.append("team", teamObj.value));
    selectedNationalities.forEach((n) => params.append("nationality", n.value));
    selectedPositions.forEach((p) => params.append("position", p.value));

    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    // Indicate we are loading players now
    setIsLoadingPlayers(true);

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPlayers(data);
        setSelectedPlayerId("");
      })
      .catch(console.error)
      .finally(() => {
        // Stop loading once the fetch is done (success or error)
        setIsLoadingPlayers(false);
      });
  }, [selectedTeams, selectedNationalities, selectedPositions]);

  // Notify parent if a new player is selected
  useEffect(() => {
    if (selectedPlayerId) {
      onPlayerSelected(selectedPlayerId);
    }
  }, [selectedPlayerId, onPlayerSelected]);

  return (
    <div
      style={{
        marginBottom: "1rem",
        padding: "1rem",
        border: "1px solid #ccc",
      }}
    >
      <h3 className={styles.compareTo}>{label}</h3>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        {/* TEAMS */}
        <div style={{ minWidth: "200px" }}>
          <label className={styles.filterLabel}>Team</label>
          <Select
            isMulti
            options={teamOptions}
            value={selectedTeams}
            onChange={(val) => setSelectedTeams(val || [])}
          />
        </div>

        {/* NATIONALITIES */}
        <div style={{ minWidth: "200px" }}>
          <label className={styles.filterLabel}>Nationality</label>
          <Select
            isMulti
            options={nationalityOptions}
            value={selectedNationalities}
            onChange={(val) => setSelectedNationalities(val || [])}
          />
        </div>

        {/* POSITIONS */}
        <div style={{ minWidth: "200px" }}>
          <label className={styles.filterLabel}>Position</label>
          <Select
            isMulti
            options={positionOptions}
            value={selectedPositions}
            onChange={(val) => setSelectedPositions(val || [])}
          />
        </div>
      </div>

      <hr />

      <h4>Filtered Players</h4>

      {/* Conditionally render a loading spinner or the select dropdown */}
      {isLoadingPlayers ? (
        <div>Loading...</div>
      ) : (
        <select
          className={styles.playerSelect}
          value={selectedPlayerId}
          onChange={(e) => setSelectedPlayerId(e.target.value)}
        >
          <option value="">-- Select Player --</option>
          {players.map((p) => (
            <option key={p.id} value={p.id}>
              {p.first_name} {p.last_name} — {Math.round(p.overall_percentile)}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}
