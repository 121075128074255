// import React, { useState, useEffect, useCallback } from 'react';
// import SeasonDropdown from '../components/SeasonDropdown';
// import SnakeChart from '../components/SnakeChart';
// import Select from 'react-select';
// import styles from '../styles/CompareSnakes.module.css';
// import { getNationalityName } from '../helpers/NationalityMapping';
//
// const statOptions = [
//   { value: 'game_rating', text: 'Game Rating' },
//   { value: 'cumulative_game_rating', text: 'Cumulative Game Rating' },
//   { value: 'expected_goals_scored', text: 'Expected Goals Scored' },
//   { value: 'cumulative_expected_goals_scored', text: 'Cumulative Expected Goals Scored' },
//   { value: 'goals_above_expected', text: 'Goals Above Expected' },
//   { value: 'cumulative_goals_above_expected', text: 'Cumulative Goals Above Expected' },
// ];
//
// export default function CompareSnakesPage() {
//   // Season and stat state
//   const [selectedSeason, setSelectedSeason] = useState('20242025');
//   const [selectedStat, setSelectedStat] = useState(statOptions[0].value);
//
//   // Filter states for the search bar
//   const [teamFilter, setTeamFilter] = useState([]);
//   const [nationalityFilter, setNationalityFilter] = useState([]);
//   const [positionFilter, setPositionFilter] = useState([]);
//   const [nameFilter, setNameFilter] = useState('');
//
//   // Options fetched from API
//   const [teamOptions, setTeamOptions] = useState([]);
//   const [nationalityOptions, setNationalityOptions] = useState([]);
//
//   // List of players fetched from /api/skaters/ based on filters
//   const [filteredPlayers, setFilteredPlayers] = useState([]);
//
//   // Selected players for the two dropdowns (storing player IDs as strings)
//   const [selectedPlayers, setSelectedPlayers] = useState([null, null]);
//
//   // Chart states
//   const [loadingChart, setLoadingChart] = useState(false);
//   const [snakeData, setSnakeData] = useState(null);
//
//   // --- Fetch team and nationality options on mount ---
//   useEffect(() => {
//     async function getOptions() {
//       try {
//         const teamResp = await fetch("/api/teams/");
//         const teamData = await teamResp.json();
//         const mappedTeams = teamData.map((t) => ({
//           value: t.abb,
//           label: t.name,
//         }));
//         setTeamOptions(mappedTeams);
//
//         const natResp = await fetch("/api/nationalities/");
//         const natData = await natResp.json();
//         const mappedNats = natData.map((abbr) => ({
//           value: abbr,
//           label: getNationalityName(abbr),
//         }));
//         setNationalityOptions(mappedNats);
//       } catch (err) {
//         console.error("Error fetching team/nationality options:", err);
//       }
//     }
//     getOptions();
//   }, []);
//
//   // --- Fetch filtered players from /api/skaters/ based on filters ---
//   const fetchFilteredPlayers = useCallback(() => {
//     let url = '/api/skaters/';
//     const params = new URLSearchParams();
//     if (teamFilter.length) {
//       teamFilter.forEach(option => params.append('team', option.value));
//     }
//     if (nationalityFilter.length) {
//       nationalityFilter.forEach(option => params.append('nationality', option.value));
//     }
//     if (positionFilter.length) {
//       positionFilter.forEach(option => params.append('position', option.value));
//     }
//     if (nameFilter.trim() !== '') {
//       params.set('name', nameFilter.trim());
//     }
//     if (params.toString()) {
//       url += `?${params.toString()}`;
//     }
//     fetch(url)
//       .then(res => res.json())
//       .then(data => {
//         setFilteredPlayers(data);
//       })
//       .catch(err => console.error('Error fetching players:', err));
//   }, [teamFilter, nationalityFilter, positionFilter, nameFilter]);
//
//   // Debounce name search so we don't fetch on every keystroke.
//   useEffect(() => {
//     const debounceTimeout = setTimeout(() => {
//       fetchFilteredPlayers();
//     }, 500);
//     return () => clearTimeout(debounceTimeout);
//   }, [fetchFilteredPlayers, nameFilter]);
//
//   // Also re-fetch when other filters change.
//   useEffect(() => {
//     fetchFilteredPlayers();
//   }, [teamFilter, nationalityFilter, positionFilter, fetchFilteredPlayers]);
//
//   // --- Options for player dropdowns ---
//   // Both dropdowns display the same list of players, excluding any player already selected in the other dropdown.
//   const optionsForDropdown = (dropdownIndex) => {
//     return filteredPlayers
//       .filter(player => {
//         const otherIndex = dropdownIndex === 0 ? 1 : 0;
//         return selectedPlayers[otherIndex] !== player.id.toString();
//       })
//       .map(player => ({
//         value: player.id.toString(),
//         label: `${player.first_name} ${player.last_name} (${player.team})`
//       }));
//   };
//
//   const handlePlayerSelect = (index, selectedOption) => {
//     setSelectedPlayers(prev => {
//       const newSelection = [...prev];
//       newSelection[index] = selectedOption ? selectedOption.value : null;
//       return newSelection;
//     });
//   };
//
//   // --- Generate Chart ---
//   const canGenerate = selectedPlayers.every(p => !!p);
//
//   const handleGenerateChart = async () => {
//     if (!canGenerate) {
//       console.warn("Please select two players.");
//       return;
//     }
//     setLoadingChart(true);
//     setSnakeData(null);
//
//     // selectedPlayers already contains the player IDs as strings.
//     const params = new URLSearchParams({
//       season: selectedSeason,
//       stat: selectedStat,
//       group_by: 'player',
//       cumulative: selectedStat.includes('cumulative') ? 'true' : 'false',
//       x_axis: 'week',
//       max_games: '10'
//     });
//     selectedPlayers.forEach(id => params.append('player_ids', id));
//
//     try {
//       const response = await fetch(`/api/snake-data/?${params.toString()}`);
//       if (!response.ok) throw new Error("Network response was not ok");
//       const data = await response.json();
//       setSnakeData(data);
//     } catch (err) {
//       console.error("Error fetching chart data:", err);
//     } finally {
//       setLoadingChart(false);
//     }
//   };
//
//   return (
//     <div className={styles.pageContainer}>
//       <h1 className={styles.pageTitle}>Compare Player Stats</h1>
//
//       {/* Season Dropdown */}
//       <SeasonDropdown selectedSeason={selectedSeason} onSeasonChange={setSelectedSeason} />
//
//       {/* Filtering Bar */}
//       <div className={styles.filterBar}>
//         <div className={styles.filterGroup}>
//           <label className={styles.filterLabel}>Team</label>
//           <Select
//             isMulti
//             options={teamOptions}
//             placeholder="Filter by Team"
//             onChange={setTeamFilter}
//             value={teamFilter}
//           />
//         </div>
//         <div className={styles.filterGroup}>
//           <label className={styles.filterLabel}>Nationality</label>
//           <Select
//             isMulti
//             options={nationalityOptions}
//             placeholder="Filter by Nationality"
//             onChange={setNationalityFilter}
//             value={nationalityFilter}
//           />
//         </div>
//         <div className={styles.filterGroup}>
//           <label className={styles.filterLabel}>Position</label>
//           <Select
//             isMulti
//             options={[{ value: 'C', label: 'C' }, { value: 'L', label: 'L' }, { value: 'R', label: 'R' }, { value: 'D', label: 'D' }]}
//             placeholder="Filter by Position"
//             onChange={setPositionFilter}
//             value={positionFilter}
//           />
//         </div>
//         <div className={styles.filterGroup}>
//           <label className={styles.filterLabel}>Name</label>
//           <input
//             type="text"
//             placeholder="Search by Name"
//             value={nameFilter}
//             onChange={(e) => setNameFilter(e.target.value)}
//             className={styles.searchInput}
//           />
//         </div>
//       </div>
//
//       {/* Two Player Dropdowns */}
//       <div className={styles.playerSelectors}>
//         <div className={styles.playerSelector}>
//           <label>Player 1</label>
//           <Select
//             options={optionsForDropdown(0)}
//             onChange={(option) => handlePlayerSelect(0, option)}
//             value={optionsForDropdown(0).find(opt => opt.value === selectedPlayers[0]) || null}
//             placeholder="Select Player 1"
//           />
//         </div>
//         <div className={styles.playerSelector}>
//           <label>Player 2</label>
//           <Select
//             options={optionsForDropdown(1)}
//             onChange={(option) => handlePlayerSelect(1, option)}
//             value={optionsForDropdown(1).find(opt => opt.value === selectedPlayers[1]) || null}
//             placeholder="Select Player 2"
//           />
//         </div>
//       </div>
//
//       {/* Stat Selector */}
//       <div className={styles.statSelectorWrapper}>
//         <label htmlFor="stat-select">Select Stat</label>
//         <select
//           id="stat-select"
//           value={selectedStat}
//           onChange={(e) => setSelectedStat(e.target.value)}
//           className={styles.statSelector}
//         >
//           {statOptions.map(opt => (
//             <option key={opt.value} value={opt.value}>{opt.text}</option>
//           ))}
//         </select>
//       </div>
//
//       {/* Generate Chart Button */}
//       <button
//         className={styles.generateButton}
//         onClick={handleGenerateChart}
//         disabled={!canGenerate || loadingChart}
//       >
//         {loadingChart ? 'Loading...' : 'Generate Chart'}
//       </button>
//
//       {/* Render the SnakeChart if data is available */}
//       {snakeData?.data && (
//         <SnakeChart
//           data={snakeData.data}
//           playerNames={snakeData.player_names}
//           groupColors={snakeData.group_colors}
//           yAxisLabel={snakeData.y_axis_label}
//           season={selectedSeason}
//           playerInfo={snakeData.mug_info}  // Pass the mug_info object here!
//         />
//       )}
//     </div>
//   );
// }
//
// // export default CompareSnakesPage;
import React, { useState } from 'react';
import styles from '../styles/CompareSnakes.module.css';
import ComparePlayerSnakes from '../components/ComparePlayerSnakes';
import CompareTeamSnakes from '../components/CompareTeamSnakes';
import SeasonDropdown from '../components/SeasonDropdown';

export default function CompareSnakesPage() {
  // "compareType" can be "players", "goalies", or "teams"
  const [compareType, setCompareType] = useState('players');
  const [selectedSeason, setSelectedSeason] = useState('20242025');

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageTitle}>
        Compare {compareType === 'teams' ? 'Team' : (compareType === 'goalies' ? 'Goalie' : 'Player')} Stats
      </h1>

      {/* Season Dropdown at the top */}
      <SeasonDropdown selectedSeason={selectedSeason} onSeasonChange={setSelectedSeason} />

      {/* Top-level Toggle */}
        <div className={styles.compareToggle}>
            <input
                type="radio"
                id="comparePlayers"
                name="compareType"
                value="players"
                checked={compareType === 'players'}
                onChange={() => setCompareType('players')}
            />
            <label htmlFor="comparePlayers">Players</label>

            <input
                type="radio"
                id="compareGoalies"
                name="compareType"
                value="goalies"
                checked={compareType === 'goalies'}
                onChange={() => setCompareType('goalies')}
            />
            <label htmlFor="compareGoalies">Goalies</label>

            <input
                type="radio"
                id="compareTeams"
                name="compareType"
                value="teams"
                checked={compareType === 'teams'}
                onChange={() => setCompareType('teams')}
            />
            <label htmlFor="compareTeams">Teams</label>
        </div>

        {/* Conditional rendering based on compareType */}
        {compareType === 'teams' ? (
            <CompareTeamSnakes season={selectedSeason}/>
        ) : (
            <ComparePlayerSnakes compareType={compareType} season={selectedSeason}/>
        )}
    </div>
  );
}
