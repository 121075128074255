// ShotTooltip.js
import React from 'react';
import styles from "../styles/GamePage.module.css";

const ShotTooltip = ({ active, payload, label }) => {
  console.log("ShotTooltip render", { active, payload, label });
  if (active && payload && payload.length) {
    const shot = payload[0].payload;
    let backgroundColor = 'inherit';
    let content;

    if (shot.goals_scored === 1) {
      backgroundColor = 'pink';
      content = (
        <>
          <em>{shot.strength_str} Goal!</em>
          <br />
          {shot.shot_type}
          <br />
          {shot.xg_value} xG
          <br />
          Scored by <strong>{shot.shooter_name}</strong>
          <br />
          {shot.assist_str}
          <br />
          {shot.time_remaining} remaining in period {shot.period}
        </>
      );
    } else if (shot.sog === 1) {
      content = (
        <>
          <strong>{shot.xg_value} xG</strong>
          <br />
          Shot on goal by {shot.shooter_name}
          <br />
          {shot.time_remaining} remaining in period {shot.period}
        </>
      );
    } else {
      content = (
        <>
          <strong>{shot.xg_value} xG</strong>
          <br />
          Shot attempt by {shot.shooter_name}
          <br />
          {shot.time_remaining} remaining in period {shot.period}
        </>
      );
    }

    return (
      <div
        className={styles.tooltip}
        style={{
          backgroundColor,
          padding: '10px',
          borderRadius: '4px',
          boxShadow: '0px 0px 5px rgba(0,0,0,0.3)'
        }}
      >
        <p>{content}</p>
        <img
          src={`https://assets.nhle.com/mugs/nhl/${shot.season}/${shot.playing_for}/${shot.shooter_id}.png`}
          style={{ width: '75px', height: 'auto', marginTop: '8px' }}
          alt="Player mug"
        />
      </div>
    );
  }
  return null;
};

// export default ShotTooltip;


// MinimalTooltip.js

const MinimalTooltip = ({ active, payload, label, coordinate }) => {
  // console.log("MinimalTooltip", { active, payload, label, coordinate });
  if (active && payload && payload.length) {
    const shot = payload[0].payload;
    // If the shot data is missing, return null to avoid rendering an empty tooltip.
    if (!shot.shooter_name) {
      return null;
    }
    let backgroundColor = 'rgba(255,255,255,0.9)';
    let content;
    if (shot.goals_scored === 1) {
      backgroundColor = 'rgba(255,182,193,0.8)'; // semi-transparent pink
      content = (
        <>
          <em>{shot.strength_str} Goal!</em>
          <br />
          {shot.shot_type}
          <br />
          {shot.xg_value} xG
          <br />
          Scored by <strong>{shot.shooter_name}</strong>
          <br />
          {shot.assist_str}
          <br />
          {shot.time_remaining} remaining in period {shot.period}
          <br/>
          {shot.score_str}
        </>
      );
    } else if (shot.sog === 1) {
      content = (
        <>
          <strong>{shot.xg_value} xG</strong>
          <br />
          Shot on goal by {shot.shooter_name}
          <br />
          {shot.time_remaining} remaining in period {shot.period}
        </>
      );
    } else {
      content = (
          <>
              <strong>{shot.xg_value} xG</strong>
              <br/>
              Shot attempt by {shot.shooter_name}
              <br/>
              {shot.time_remaining} remaining in period {shot.period}
          </>
      );
    }
    const tooltipStyle = {
        position: 'absolute',
        width: 'clamp(150px, 16vw, 250px)',
        zIndex: 9999,
        background: backgroundColor,
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        left: coordinate ? coordinate.x - 150 : 0,  // adjust these values
        // top: '-100px',
        top: coordinate ? coordinate.y - 150 : 0,   // adjust these values
    };
    return (
        <div style={tooltipStyle}>
            {/*<div><strong>{shot.shooter_name}</strong></div>*/}
            {/*<div>{shot.xg_value} xG</div>*/}
            <p>{content}</p>
            <img
                src={`https://assets.nhle.com/mugs/nhl/${shot.season}/${shot.playing_for}/${shot.shooter_id}.png`}
                style={{width: '75px', height: 'auto', marginTop: '8px'}}
                alt="Player mug"
            />
        </div>
    );
  }
    return null;
};

export default MinimalTooltip;
