import React, {Component, useEffect, useState} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import TeamDetailPage from "./TeamPlayersPage";
import styles from '../styles/TeamPlayersPage.module.css'
import { useParams } from 'react-router-dom'
import {getColor} from "../helpers/colors";


// function RenderPlayerList(players) {
//     return players.map(player => (
//         <Link className={styles.teamRows} to={`/players/id/${player.id}`} key={player.id}>
//         <li className={styles.listGroupItem}>
//         # Enter Overall Circle Here
//         <div className={styles.playerName}>
//             {player.first_name + ' ' + player.last_name}
//         </div>
//         <span className={styles.mugContainer}>
//             <img className={styles.mug}
//                  src={`https://assets.nhle.com/mugs/nhl/20242025/${player.current_team}/${player.id}.png`}
//                  alt={`${player.first_name + player.last_name}`} />
//         </span>
//     </li>
// </Link>
//     ));
// }
function RenderPlayerList(players) {
    return players.map(player => (
        <Link className={styles.teamRows} to={`/players/id/${player.id}`} key={player.id}>
            <li className={styles.listGroupItem}>
                {/* Overall Circle */}
                <div
                    className={styles.overallCircle}
                    style={{ borderColor: getColor(player.overall_percentile) }}
                >
                    {Math.round(player.overall_percentile || 0)}
                </div>
                <div className={styles.playerName}>
                    {player.first_name + ' ' + player.last_name}
                </div>
                <span className={styles.mugContainer}>
                    <img
                        className={styles.mug}
                        src={`https://assets.nhle.com/mugs/nhl/20242025/${player.current_team}/${player.id}.png`}
                        alt={`${player.first_name} ${player.last_name}`}
                    />
                </span>
            </li>
        </Link>
    ));
}

function PlayersTable(players, team) {
    const params = useParams();
    const teamAbb = params.teamAbb
    // console.log(`Look Here, I found it! ${team.location + team.name}`)
    // const teamName = {team.location + team.name}
    return (
        <main className={styles.mainTable}>
            {/*<img className={styles.teamLogo}*/}
            {/*     src={`https://assets.nhle.com/logos/nhl/svg/${teamAbb}_light.svg`}*/}
            {/*     alt={`${teamAbb} logo`}/>*/}
            <Link className={styles.noDecorationLink} to={`/teams/${team.abb}`} key={team.abb}>
                <h1 className={styles.teamTableHeading}>
                    <img className={styles.teamLogo}
                             src={`https://assets.nhle.com/logos/nhl/svg/${teamAbb}_light.svg`}
                             alt={`${teamAbb} logo`}
                    />
                    {team.location + ' ' + team.name}
                </h1>
            </Link>
            <div className="row">
                <div className="text-center col-me-6 col-sma-10 mc-auto p-0 ">
                    <div className="card p-3 text-center">
                        <ul className="list-group">
                            {RenderPlayerList(players)}
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
}

function TeamPlayersPage() {
    // const teamAbb = searchParams.get('teamAbb')
    const params = useParams();
    const teamAbb = params.teamAbb
    const [players, setPlayers] = useState([])
    const [team, setTeam] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
            .catch(err => {
                console.log(err);
      });
    }, [])

    const getData = async () => {
        setLoading(true)
        let apiUrl =
        `/api/players/${teamAbb}`
        const playerListResp = await fetch(apiUrl)
        const playerList = await playerListResp.json()
        setPlayers(playerList)

        apiUrl = `/api/teams/${teamAbb}`
        const teamResp = await fetch(apiUrl)
        const teamData = await teamResp.json()
        // console.log(teamData)
        setTeam(teamData)
        setLoading(false)
    }
    if(loading) return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );

    return (
        PlayersTable(players, team)
    )

}

export default TeamPlayersPage


