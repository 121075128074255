import React from "react";
import ComparePlayersSharedIce from "../components/ComparePlayersSharedIce";
// Adjust the path to wherever ComparePlayers.js actually lives

function CompareSharedIce() {
  return (
    <div>

      <main>
        {/* Render the ComparePlayers component at the top */}
        <ComparePlayersSharedIce />

        {/* Below, you could add any other content you want
            on this page—such as instructions, sidebars, etc. */}
        <section style={{ marginTop: "2rem" }}>
        </section>
      </main>
    </div>
  );
}

export default CompareSharedIce;
