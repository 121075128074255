// import React, { useState, useEffect, useCallback } from 'react';
// import SeasonDropdown from '../components/SeasonDropdown';
// import Select from 'react-select';
// import SnakeChart from '../components/SnakeChart';
// import styles from '../styles/CompareSnakes.module.css';
// import { getNationalityName } from '../helpers/NationalityMapping';
//
// const statOptionsPlayers = [
//   { value: 'game_rating', text: 'Game Rating' },
//   { value: 'cumulative_game_rating', text: 'Cumulative Game Rating' },
//   { value: 'expected_goals_scored', text: 'Expected Goals Scored' },
//   { value: 'cumulative_expected_goals_scored', text: 'Cumulative Expected Goals Scored' },
//   { value: 'cumulative_goals_above_expected', text: 'Cumulative Goals Above Expected' },
//   { value: 'net_expected_goals_on_ice_even', text: 'Net EV xG On Ice For' },
//   { value: 'cumulative_net_expected_goals_on_ice_even', text: 'Cumulative Net EV xG On Ice For' },
// ];
//
// const statOptionsGoalies = [
//   { value: 'game_rating', text: 'Game Rating' },
//   { value: 'cumulative_game_rating', text: 'Cumulative Game Rating' },
//   { value: 'goals_saved_above_expected', text: 'Goals Saved Above Expected' },
//   { value: 'cumulative_goals_saved_above_expected', text: 'Cumulative Goals Saved Above Expected' },
// ];
//
// const numGamesOptions = [
//   { value: 5, label: '5 Games' },
//   { value: 10, label: '10 Games' },
//   { value: 15, label: '15 Games' },
//   { value: 20, label: '20 Games' },
// ];
//
// const groupByOptions = [
//   { value: 'game', label: 'Game' },
//   { value: 'week', label: 'Week' },
// ];
//
// export default function ComparePlayerSnakes({ compareType, selectedSeason }) {
//   // "compareType" is either "players" or "goalies"
//   // Use different API endpoints based on compareType.
//   const endpoint = compareType === 'goalies' ? '/api/goalies/' : '/api/skaters/';
//
//   // Filter states: for players/goalies, we show Team, Nationality, and Position filters.
//   const [teamFilter, setTeamFilter] = useState([]);
//   const [nationalityFilter, setNationalityFilter] = useState([]);
//   const [positionFilter, setPositionFilter] = useState([]);
//   // (No name search for now.)
//
//   // Options fetched from API.
//   const [teamOptions, setTeamOptions] = useState([]);
//   const [nationalityOptions, setNationalityOptions] = useState([]);
//
//   // List of players/goalies fetched based on filters.
//   const [filteredPlayers, setFilteredPlayers] = useState([]);
//
//   // Selected players for the two dropdowns.
//   const [selectedPlayers, setSelectedPlayers] = useState([null, null]);
//
//   // Additional dropdowns:
//   const [selectedStat, setSelectedStat] = useState(
//     compareType === 'goalies' ? statOptionsGoalies[0].value : statOptionsPlayers[0].value
//   );
//   const [selectedNumGames, setSelectedNumGames] = useState(null);
//   const [selectedGroupBy, setSelectedGroupBy] = useState(null);
//
//   // Chart state.
//   const [loadingChart, setLoadingChart] = useState(false);
//   const [snakeData, setSnakeData] = useState(null);
//
//   // --- Fetch team and nationality options on mount ---
//   useEffect(() => {
//     async function getOptions() {
//       try {
//         const teamResp = await fetch("/api/teams/");
//         const teamData = await teamResp.json();
//         const mappedTeams = teamData.map((t) => ({
//           value: t.abb,
//           label: t.name,
//         }));
//         setTeamOptions(mappedTeams);
//
//         const natResp = await fetch("/api/nationalities/");
//         const natData = await natResp.json();
//         const mappedNats = natData.map((abbr) => ({
//           value: abbr,
//           label: getNationalityName(abbr),
//         }));
//         setNationalityOptions(mappedNats);
//       } catch (err) {
//         console.error("Error fetching team/nationality options:", err);
//       }
//     }
//     getOptions();
//   }, []);
//
//   // --- Fetch filtered players ---
//   const fetchFilteredPlayers = useCallback(() => {
//     let url = endpoint;
//     const params = new URLSearchParams();
//     if (teamFilter.length) {
//       teamFilter.forEach(option => params.append('team', option.value));
//     }
//     if (nationalityFilter.length) {
//       nationalityFilter.forEach(option => params.append('nationality', option.value));
//     }
//     if (positionFilter.length) {
//       positionFilter.forEach(option => params.append('position', option.value));
//     }
//     if (params.toString()) {
//       url += `?${params.toString()}`;
//     }
//     fetch(url)
//       .then(res => res.json())
//       .then(data => {
//         setFilteredPlayers(data);
//       })
//       .catch(err => console.error('Error fetching players:', err));
//   }, [endpoint, teamFilter, nationalityFilter, positionFilter]);
//
//   useEffect(() => {
//     fetchFilteredPlayers();
//   }, [fetchFilteredPlayers]);
//
//   // --- Options for player dropdowns ---
//   const optionsForDropdown = (dropdownIndex) => {
//     return filteredPlayers
//       .filter(player => {
//         const otherIndex = dropdownIndex === 0 ? 1 : 0;
//         return selectedPlayers[otherIndex] !== player.id.toString();
//       })
//       .map(player => ({
//         value: player.id.toString(),
//         label: `${player.first_name} ${player.last_name} (${player.team})`
//       }));
//   };
//
//   const handlePlayerSelect = (index, selectedOption) => {
//     setSelectedPlayers(prev => {
//       const newSelection = [...prev];
//       newSelection[index] = selectedOption ? selectedOption.value : null;
//       return newSelection;
//     });
//   };
//
//   // --- Generate Chart ---
//   const canGenerate =
//     selectedPlayers.every(p => !!p) &&
//     selectedStat &&
//     selectedNumGames &&
//     selectedGroupBy;
//
//   const handleGenerateChart = async () => {
//     if (!canGenerate) {
//       console.warn("Please complete all selections.");
//       return;
//     }
//     setLoadingChart(true);
//     setSnakeData(null);
//
//     const params = new URLSearchParams({
//       season: selectedSeason,
//       stat: selectedStat,
//       group_by: 'player',
//       cumulative: selectedStat.includes('cumulative') ? 'true' : 'false',
//       x_axis: selectedGroupBy,
//       max_games: selectedNumGames.toString(),
//     });
//     selectedPlayers.forEach(id => params.append('player_ids', id));
//
//     try {
//       const response = await fetch(`/api/snake-data/?${params.toString()}`);
//       if (!response.ok) throw new Error("Network response was not ok");
//       const data = await response.json();
//       setSnakeData(data);
//     } catch (err) {
//       console.error("Error fetching chart data:", err);
//     } finally {
//       setLoadingChart(false);
//     }
//   };
//
//   return (
//     <div className={styles.pageContainer}>
//       <h2 className={styles.sectionTitle}>
//         Compare {compareType === 'goalies' ? 'Goalies' : 'Players'}
//       </h2>
//       {/* Filtering Bar */}
//       <div className={styles.filterBar}>
//         <div className={styles.filterGroup}>
//           <label>Team</label>
//           <Select
//             isMulti
//             options={teamOptions}
//             placeholder="Filter by Team"
//             onChange={setTeamFilter}
//             value={teamFilter}
//           />
//         </div>
//         <div className={styles.filterGroup}>
//           <label>Nationality</label>
//           <Select
//             isMulti
//             options={nationalityOptions}
//             placeholder="Filter by Nationality"
//             onChange={setNationalityFilter}
//             value={nationalityFilter}
//           />
//         </div>
//         <div className={styles.filterGroup}>
//           <label>Position</label>
//           <Select
//             isMulti
//             options={[
//               { value: 'C', label: 'C' },
//               { value: 'L', label: 'L' },
//               { value: 'R', label: 'R' },
//               { value: 'D', label: 'D' },
//             ]}
//             placeholder="Filter by Position"
//             onChange={setPositionFilter}
//             value={positionFilter}
//           />
//         </div>
//       </div>
//
//       {/* Two Player Dropdowns */}
//       <div className={styles.playerSelectors}>
//         <div className={styles.playerSelector}>
//           <label>Player 1</label>
//           <Select
//             options={optionsForDropdown(0)}
//             onChange={(option) => handlePlayerSelect(0, option)}
//             value={optionsForDropdown(0).find(opt => opt.value === selectedPlayers[0]) || null}
//             placeholder="Select Player 1"
//           />
//         </div>
//         <div className={styles.playerSelector}>
//           <label>Player 2</label>
//           <Select
//             options={optionsForDropdown(1)}
//             onChange={(option) => handlePlayerSelect(1, option)}
//             value={optionsForDropdown(1).find(opt => opt.value === selectedPlayers[1]) || null}
//             placeholder="Select Player 2"
//           />
//         </div>
//       </div>
//
//       {/* Additional Dropdowns */}
//       <div className={styles.additionalFilters}>
//         <div className={styles.filterGroup}>
//           <label>Select Stat</label>
//           <select
//             value={selectedStat}
//             onChange={(e) => setSelectedStat(e.target.value)}
//             className={styles.statSelector}
//           >
//             {(compareType === 'goalies'
//               ? statOptionsGoalies
//               : statOptionsPlayers
//             ).map(opt => (
//               <option key={opt.value} value={opt.value}>{opt.text}</option>
//             ))}
//           </select>
//         </div>
//         <div className={styles.filterGroup}>
//           <label>Number of Games</label>
//           <Select
//             options={numGamesOptions}
//             onChange={(option) => setSelectedNumGames(option.value)}
//             value={numGamesOptions.find(opt => opt.value === selectedNumGames) || null}
//             placeholder="Select # of Games"
//           />
//         </div>
//         <div className={styles.filterGroup}>
//           <label>Group By</label>
//           <Select
//             options={groupByOptions}
//             onChange={(option) => setSelectedGroupBy(option.value)}
//             value={groupByOptions.find(opt => opt.value === selectedGroupBy) || null}
//             placeholder="Select Group By"
//           />
//         </div>
//       </div>
//
//       {/* Generate Chart Button */}
//       <button
//         className={styles.generateButton}
//         onClick={handleGenerateChart}
//         disabled={!canGenerate || loadingChart}
//       >
//         {loadingChart ? 'Loading...' : 'Generate Chart'}
//       </button>
//
//       {/* Render the SnakeChart if data is available */}
//       {snakeData?.data && (
//         <SnakeChart
//           data={snakeData.data}
//           playerNames={snakeData.player_names}
//           groupColors={snakeData.group_colors}
//           yAxisLabel={snakeData.y_axis_label}
//           season={selectedSeason}
//           playerInfo={snakeData.mug_info}
//         />
//       )}
//     </div>
//   );
// }
import React, { useState, useEffect, useCallback } from 'react';
import SeasonDropdown from '../components/SeasonDropdown';
import Select from 'react-select';
import SnakeChart from '../components/SnakeChart';
import styles from '../styles/CompareSnakes.module.css';
import { getNationalityName } from '../helpers/NationalityMapping';
import SnakeChartInputFilters from "./SnakeChartInputFilters";

const statOptionsPlayers = [
  { value: 'game_rating', label: 'Game Rating' },
  { value: 'cumulative_game_rating', label: 'Cumulative Game Rating' },
  { value: 'expected_goals_scored', label: 'Expected Goals Scored' },
  { value: 'cumulative_expected_goals_scored', label: 'Cumulative Expected Goals Scored' },
  { value: 'cumulative_goals_above_expected', label: 'Cumulative Goals Above Expected' },
  { value: 'net_expected_goals_on_ice_even', label: 'Net EV xG On Ice For' },
  { value: 'cumulative_net_expected_goals_on_ice_even', label: 'Cumulative Net EV xG On Ice For' },
];

const statOptionsGoalies = [
  { value: 'game_rating', label: 'Game Rating' },
  { value: 'cumulative_game_rating', label: 'Cumulative Game Rating' },
  { value: 'goals_saved_above_expected', label: 'Goals Saved Above Expected' },
  { value: 'cumulative_goals_saved_above_expected', label: 'Cumulative Goals Saved Above Expected' },
];

// const numGamesOptions = [
//   { value: 5, label: '5 Games' },
//   { value: 10, label: '10 Games' },
//   { value: 15, label: '15 Games' },
//   { value: 20, label: '20 Games' },
// ];

const groupByOptions = [
  { value: 'game', label: 'Games' },
  { value: 'week', label: 'Weeks' },
];

export default function ComparePlayerSnakes({ compareType, season }) {
  const endpoint = '/api/players/';

  const [chartTitle, setChartTitle] = useState('');

  // Optional filters (displayed in one row):
  const [teamFilter, setTeamFilter] = useState([]);
  const [nationalityFilter, setNationalityFilter] = useState([]);
  const [positionFilter, setPositionFilter] = useState([]);

  // Options fetched from API:
  const [teamOptions, setTeamOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);

  // List of players fetched from API:
  const [filteredPlayers, setFilteredPlayers] = useState([]);

  // Selected players (required):
  const [selectedPlayers, setSelectedPlayers] = useState([null, null]);

  // Additional required dropdowns:
  const [selectedStat, setSelectedStat] = useState(
    compareType === 'goalies' ? statOptionsGoalies[0].value : statOptionsPlayers[0].value
  );
  // const [selectedNumGames, setSelectedNumGames] = useState(null);
  const [selectedNumTimePeriods, setSelectedNumTimePeriods] = useState(null);
  const [selectedGroupBy, setSelectedGroupBy] = useState(null);

  // Chart state:
  const [loadingChart, setLoadingChart] = useState(false);
  const [snakeData, setSnakeData] = useState(null);

  // --- Fetch options for team and nationality filters ---
  useEffect(() => {
    async function getOptions() {
      try {
        const teamResp = await fetch("/api/teams/");
        const teamData = await teamResp.json();
        const mappedTeams = teamData.map((t) => ({
          value: t.abb,
          label: t.location + ' ' + t.name,
        }));
        setTeamOptions(mappedTeams);

        const natResp = await fetch("/api/nationalities/");
        const natData = await natResp.json();
        const mappedNats = natData.map((abbr) => ({
          value: abbr,
          label: getNationalityName(abbr),
        }));
        setNationalityOptions(mappedNats);
      } catch (err) {
        console.error("Error fetching options:", err);
      }
    }
    getOptions();
  }, []);

  // --- Fetch filtered players based on optional filters ---
  const fetchFilteredPlayers = useCallback(() => {
    let url = endpoint;
    const params = new URLSearchParams();
    if (teamFilter.length) {
      teamFilter.forEach(option => params.append('team', option.value));
    }
    if (nationalityFilter.length) {
      nationalityFilter.forEach(option => params.append('nationality', option.value));
    }
    if (compareType === 'goalies') {
      params.append('position', 'G');
    } else if (positionFilter.length) {
      positionFilter.forEach(option => params.append('position', option.value));
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setFilteredPlayers(data);
      })
      .catch(err => console.error('Error fetching players:', err));
  }, [endpoint, teamFilter, nationalityFilter, positionFilter, compareType]);

  useEffect(() => {
    fetchFilteredPlayers();
  }, [fetchFilteredPlayers]);

  const optionsForDropdown = (dropdownIndex) => {
  // Get the value of the other dropdown (as a string) so we can filter it out.
  const otherIndex = dropdownIndex === 0 ? 1 : 0;
  const otherSelected = selectedPlayers[otherIndex]?.value; // Now safe, because selectedPlayers contains full objects
  // Map the filtered players into option objects.
  const optionsFromFiltered = filteredPlayers
    .filter(player => player.id.toString() !== otherSelected)
    .map(player => ({
      value: player.id.toString(),
      label: `${player.first_name} ${player.last_name} (${player.current_team})`,
    }));
  // Get the current selection for this dropdown.
  const currentSelection = selectedPlayers[dropdownIndex];
  // If there is a selected option and it's not in the list, add it.
  const alreadyPresent = currentSelection && optionsFromFiltered.some(opt => opt.value === currentSelection.value);
  if (currentSelection && !alreadyPresent) {
    return [currentSelection, ...optionsFromFiltered];
  }
  return optionsFromFiltered;
};

  const handlePlayerSelect = (index, selectedOption) => {
  //   setSelectedPlayers(prev => {
  //     const newSelection = [...prev];
  //     newSelection[index] = selectedOption ? selectedOption.value : null;
  //     return newSelection;
  //   });
  // };
    setSelectedPlayers(prev => {
    const newSelection = [...prev];
    newSelection[index] = selectedOption || null; // selectedOption is the full object
    return newSelection;
  });
};

  // --- Generate Chart ---
  const canGenerate =
    selectedPlayers.every(p => !!p) &&
    selectedStat &&
    // selectedNumGames &&
    selectedNumTimePeriods &&
    selectedGroupBy;

  const handleGenerateChart = async () => {
    if (!canGenerate) {
      console.warn("Please complete all selections.");
      return;
    }

    const selectedStatLabel = (compareType === 'goalies' ? statOptionsGoalies : statOptionsPlayers)
      .find(opt => opt.value === selectedStat)?.label || selectedStat;

    const title = `${selectedStatLabel}: Last ${selectedNumTimePeriods} ${selectedGroupBy === 'week' ? 'Weeks' : 'Games'}`;
    setChartTitle(title);

    setLoadingChart(true);
    setSnakeData(null);

    const params = new URLSearchParams({
      season,
      stat: selectedStat,
      group_by: 'player',
      cumulative: selectedStat.includes('cumulative') ? 'true' : 'false',
      x_axis: selectedGroupBy,
      // max_games: selectedNumGames.toString(),
      max_time_periods: selectedNumTimePeriods.toString(),
    });
    // selectedPlayers.forEach(id => params.append('player_ids', id));
      selectedPlayers.forEach(playerOption => {
      if (playerOption) {
        params.append('player_ids', playerOption.value);
      }
    });

    try {
      const response = await fetch(`/api/snake-data/?${params.toString()}`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setSnakeData(data);
    } catch (err) {
      console.error("Error fetching chart data:", err);
    } finally {
      setLoadingChart(false);
    }
  };

  return (
      <div className={styles.pageContainer}>
        <h2 className={styles.sectionTitle}>
          Compare {compareType === 'goalies' ? 'Goalies' : 'Players'}
        </h2>

        {/* Optional Filters: Display these only for Players/Goalies */}
        <div className={styles.optionalFilters}>
          <h3 className={styles.optionalHeader}>Filters</h3>
          <div className={styles.filterBar}>
            <div className={styles.filterGroup}>
              <label>Team</label>
              <Select
                  className={styles.playerDropdown}
                  isMulti
                  options={teamOptions}
                  placeholder="Filter by Team"
                  onChange={setTeamFilter}
                  value={teamFilter}
              />
            </div>
            <div className={styles.filterGroup}>
              <label>Nationality</label>
              <Select
                  className={styles.positionNationalityDropdown}
                  isMulti
                  options={nationalityOptions}
                  placeholder="Filter by Nationality"
                  onChange={setNationalityFilter}
                  value={nationalityFilter}
              />
            </div>
            <div className={styles.filterGroup}>
              <label>Position</label>
              <Select
                  className={styles.positionNationalityDropdown}
                  isMulti
                  options={[
                    {value: 'C', label: 'Center'},
                    {value: 'L', label: 'Left Wing'},
                    {value: 'R', label: 'Right Wing'},
                    {value: 'D', label: 'Defense'},
                  ]}
                  placeholder="Filter by Position"
                  onChange={setPositionFilter}
                  value={positionFilter}
              />
            </div>
          </div>
        </div>

        {/* Required selectors: Two Player Dropdowns */}
        <div className={styles.playerSelectors}>
          <div className={styles.playerSelector}>
            <label>Player 1</label>
            <Select
                className={styles.playerDropdown}
                options={optionsForDropdown(0)}
                onChange={(option) => handlePlayerSelect(0, option)}
                // value={optionsForDropdown(0).find(opt => opt.value === selectedPlayers[0]) || null}
                value={selectedPlayers[0]}
                placeholder="Select Player 1"
            />
          </div>
          <div className={styles.playerSelector}>
            <label>Player 2</label>
            <Select
                className={styles.playerDropdown}
                options={optionsForDropdown(1)}
                onChange={(option) => handlePlayerSelect(1, option)}
                // value={optionsForDropdown(1).find(opt => opt.value === selectedPlayers[1]) || null}
                value={selectedPlayers[1]}
                placeholder="Select Player 2"
            />
          </div>
        </div>
        {/* Additional Dropdowns */}
        <SnakeChartInputFilters
            statOptions={compareType === 'goalies' ? statOptionsGoalies : statOptionsPlayers}
            selectedStat={selectedStat}
            onChangeStat={setSelectedStat}
            selectedNum={selectedNumTimePeriods}
            onChangeNum={setSelectedNumTimePeriods}
            selectedGroupBy={selectedGroupBy}
            onChangeGroupBy={setSelectedGroupBy}
        />

        {/* Generate Chart Button */}
        <button
            className={styles.generateButton}
            onClick={handleGenerateChart}
            disabled={!canGenerate || loadingChart}
        >
          {loadingChart ? 'Loading...' : 'Generate Chart'}
        </button>

        {/* Render the title AFTER Generate Chart is clicked */}
        {chartTitle && <h3 className={styles.chartTitle}>{chartTitle}</h3>}
        {/*<h3 className={styles.chartTitle}>{`${selectedStatLabel}: Last ${selectedNumTimePeriods} ${selectedGroupBy === 'week' ? 'Weeks' : 'Games'}`}</h3>*/}

        {snakeData?.data && (
            <SnakeChart
                data={snakeData.data}
                playerNames={snakeData.player_names}
                groupColors={snakeData.group_colors}
                yAxisLabel={snakeData.y_axis_label}
                season={season}
                playerInfo={snakeData.mug_info}  // Pass mug_info for rendering mugs
            />
        )}
      </div>
  );
}
