// import React from 'react';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
//   ReferenceLine, Area,
// } from 'recharts';
// import styles from "../styles/GamePage.module.css";
// // import ShotTooltip from "./ShotTooltip";
// import MinimalTooltip from "./ShotTooltip";
//
// const XGChart = ({ data }) => {
//   // Check if data or required properties are missing, and return a fallback
//   if (!data || !data.team_information || !data.xgs) {
//     return <div>Loading chart...</div>;
//   }
//   const { team_information, xgs } = data;
//
//   // Process xgs data to insert intersection points at net_xg = 0 when needed.
//   const processedData = [];
//
//   for (let i = 0; i < xgs.length; i++) {
//     const point = xgs[i];
//     if (i === 0) {
//       processedData.push(point);
//     } else {
//       const prevPoint = processedData[processedData.length - 1];
//       // Check if the segment crosses zero (one point is positive and the other negative)
//       if (prevPoint.net_xg * point.net_xg < 0) {
//         // Calculate ratio along the segment where y = 0.
//         const ratio = prevPoint.net_xg / (prevPoint.net_xg - point.net_xg);
//         const interTimestamp =
//           prevPoint.timestamp + ratio * (point.timestamp - prevPoint.timestamp);
//         // Insert the intersection point
//         processedData.push({
//           net_xg: 0,
//           timestamp: interTimestamp,
//           // goals_scored left undefined as this is interpolated.
//         });
//       }
//       processedData.push(point);
//     }
//   }
//
//   // Helper function to determine color based on net_xg value.
//   const getColor = (net_xg) => {
//     if (net_xg === 0) return '#000000';
//     return net_xg > 0
//       ? team_information.winner_primary_color
//       : team_information.loser_primary_color;
//   };
//
//   // Build segments of the line that have a consistent color.
//   let segments = [];
//   let currentSegment = [];
//   let currentColor = null;
//
//   for (let i = 0; i < processedData.length; i++) {
//     const pt = processedData[i];
//     const ptColor = getColor(pt.net_xg);
//     if (i === 0) {
//       currentSegment.push(pt);
//       currentColor = ptColor;
//     } else {
//       // If the color hasn't changed, continue the segment.
//       if (ptColor === currentColor || pt.net_xg === 0) {
//         currentSegment.push(pt);
//       } else {
//         segments.push({ data: [...currentSegment], color: currentColor });
//         // Start new segment ensuring connectivity.
//         currentSegment = [processedData[i - 1], pt];
//         currentColor = ptColor;
//       }
//     }
//   }
//   if (currentSegment.length > 1) segments.push({ data: currentSegment, color: currentColor });
//
//   // Determine maximum timestamp for x-axis domain.
//   const maxTimestamp = processedData[processedData.length - 1].timestamp;
//
//   const ticks = Array.from(
//   { length: Math.floor(maxTimestamp / 20) + 1 },
//   (_, i) => i * 20
//   );
//
//   // Build an array of period markers (vertical reference lines) at every 20 units.
//   const periodMarkers = [];
//   for (let t = 20; t <= maxTimestamp; t += 20) {
//     periodMarkers.push(t);
//   }
//
//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <LineChart data={processedData}>
//         <CartesianGrid strokeDasharray="3 3"/>
//         <XAxis
//             dataKey="timestamp"
//             type="number"
//             domain={[0, maxTimestamp]}
//             ticks={ticks}
//             label={{value: 'Minutes Elapsed', position: 'insideBottom', offset: 0}}
//         />
//         <YAxis
//             dataKey="net_xg"
//             label={{value: 'Net Expected Goals', angle: -90, position: 'insideLeft'}}
//         />
//         <ReferenceLine y={0} stroke="#000" strokeWidth={2} strokeDasharray="3 3" />
//         {/* Render an Area for each segment, filling the area between the line and y=0 */}
//         {segments.map((segment, idx) => (
//           <Area
//             key={`area-${idx}`}
//             data={segment.data}
//             type="linear"
//             dataKey="net_xg"
//             baseValue={0}
//             stroke="none"
//             fill={segment.color}
//             fillOpacity={0.3}
//           />
//         ))}
//         <Tooltip content={<MinimalTooltip />} />
//         {/* Render vertical reference lines with labels at every 20 units */}
//         {periodMarkers.map((mark, idx) => (
//             <ReferenceLine
//                 key={idx}
//                 x={mark}
//                 stroke="grey"
//                 strokeDasharray="3 3"
//                 label={{value: mark, position: 'top', fill: 'grey', fontSize: 12}}
//             />
//         ))}
//         {/* Render each line segment with its designated color and increased stroke width */}
//         {segments.map((segment, idx) => (
//             <Line
//               key={idx}
//               data={segment.data}
//               // type="linear"
//               type="monotone"
//               dataKey="net_xg"
//               stroke={segment.color}
//               strokeWidth={5} // Bold line
//               activeDot={false}
//               dot={(props) => {
//                 const { cx, cy, payload } = props;
//                 if (payload.goals_scored === 1) {
//                   return (
//                     <image
//                       xlinkHref={`https://assets.nhle.com/logos/nhl/svg/${payload.playing_for}_light.svg`}
//                       x={cx - 25}  // Center the image (assuming 20x20)
//                       y={cy - 25}
//                       width={50}
//                       height={50}
//                       zIndex={999}
//                     />
//                   );
//                 }
//                 return <circle cx={cx} cy={cy} r={1} fill={getColor(payload.net_xg)} />;
//               }}
//               connectNulls
//             />
//         ))}
//       </LineChart>
//     </ResponsiveContainer>
//   );
// };
//
// export default XGChart;

import React from 'react';
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import ShotTooltip from './ShotTooltip';

const XGChart = ({ data }) => {
  if (!data || !data.team_information || !data.xgs) {
    return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
  }

  const {team_information, xgs} = data;

  const CenteredYAxisLabel = ({viewBox, value}) => {
    const {x, y, height} = viewBox;
    // Adjust the horizontal offset as needed.
    const cx = x + 10;
    // Center vertically within the axis area.
    const cy = y + height / 2;
    return (
      <text
        x={cx}
        y={cy}
        fill="grey"
        textAnchor="middle"
        dominantBaseline="middle"
        transform={`rotate(-90, ${cx}, ${cy})`}
        style={{ fontSize: 15 }}
      >
        {value}
      </text>
    );
  };

  // Process xgs data (inserting interpolated points at zero crossings)
  const processedData = [];
  for (let i = 0; i < xgs.length; i++) {
    const point = xgs[i];
    if (i === 0) {
      processedData.push(point);
    } else {
      const prevPoint = processedData[processedData.length - 1];
      if (prevPoint.net_xg * point.net_xg < 0) {
        const ratio = prevPoint.net_xg / (prevPoint.net_xg - point.net_xg);
        const interTimestamp =
          prevPoint.timestamp + ratio * (point.timestamp - prevPoint.timestamp);
        processedData.push({
          net_xg: 0,
          timestamp: interTimestamp,
          // Mark this as interpolated (optional)
          isInterpolated: true,
        });
      }
      processedData.push(point);
    }
  }

  // Helper: Determine color for a given net_xg value.
  const getColor = (net_xg) => {
    if (net_xg === 0) return '#000000';
    return net_xg > 0
      ? team_information.winner_primary_color
      : team_information.loser_primary_color;
  };

  // Build segments for areas/lines (each segment where color remains consistent).
  let segments = [];
  let currentSegment = [];
  let currentColor = null;
  for (let i = 0; i < processedData.length; i++) {
    const pt = processedData[i];
    const ptColor = getColor(pt.net_xg);
    if (i === 0) {
      currentSegment.push(pt);
      currentColor = ptColor;
    } else {
      if (ptColor === currentColor || pt.net_xg === 0) {
        currentSegment.push(pt);
      } else {
        segments.push({ data: [...currentSegment], color: currentColor });
        currentSegment = [processedData[i - 1], pt];
        currentColor = ptColor;
      }
    }
  }
  if (currentSegment.length > 1) {
    segments.push({ data: currentSegment, color: currentColor });
  }

  const maxTimestamp = processedData[processedData.length - 1].timestamp;
  const ticks = Array.from(
    { length: Math.floor(maxTimestamp / 20) + 1 },
    (_, i) => i * 20
  );
  const periodMarkers = [];
  for (let t = 20; t <= maxTimestamp; t += 20) {
    periodMarkers.push(t);
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart data={processedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          type="number"
          domain={[0, maxTimestamp]}
          ticks={ticks}
          label={{ value: 'Minutes', position: 'insideBottom', dx: 0, dy: 0, textAnchor: 'middle' }}
        />
        <YAxis
          dataKey="net_xg"
          domain={['auto', 'auto']}
          label={
            <CenteredYAxisLabel
              value={`${data.team_information.winner} Net Expected Goals`}
            />
          }
        />
        <Tooltip content={<ShotTooltip />} />
        {/* Horizontal line at y=0 */}
        <ReferenceLine y={0} stroke="#000" strokeWidth={2} strokeDasharray="3 3" />
        {/* Vertical period markers */}
        {periodMarkers.map((mark, idx) => (
          <ReferenceLine
            key={idx}
            x={mark}
            stroke="grey"
            strokeDasharray="3 3"
            label={{ value: mark, position: 'top', fill: 'grey', fontSize: 12 }}
          />
        ))}
        {/* Render an Area for each segment, filling the area between the line and y=0 */}
        {segments.map((segment, idx) => (
          <Area
            key={`area-${idx}`}
            data={segment.data}
            type="monotone"
            dataKey="net_xg"
            baseValue={0}
            stroke="none"
            fill={segment.color}
            fillOpacity={0.3}
          />
        ))}
        {/* Render the line segments on top */}
        {segments.map((segment, idx) => (
          <Line
            key={`line-${idx}`}
            data={segment.data}
            type="monotone"
            dataKey="net_xg"
            stroke={segment.color}
            strokeWidth={5}
            activeDot={false}
            dot={(props) => {
              const { cx, cy, payload } = props;
              if (payload.goals_scored === 1) {
                return (
                  <image
                    xlinkHref={`https://assets.nhle.com/logos/nhl/svg/${payload.playing_for}_light.svg`}
                    x={cx - 25}
                    y={cy - 25}
                    width={50}
                    height={50}
                  />
                );
              }
              return <circle cx={cx} cy={cy} r={2} fill={segment.color} />;
            }}
            connectNulls
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default XGChart;
