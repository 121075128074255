const nationalityMap = {
  USA: {
    name: "United States",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg/510px-Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg.png",
  },
  CAN: {
    name: "Canada",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Canada.svg/1600px-Flag_of_Canada.svg.png?20190318175205",
  },
  SWE: {
    name: "Sweden",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Sweden.svg/1600px-Flag_of_Sweden.svg.png?20240601033150",
  },
  FIN: {
    name: "Finland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/1600px-Flag_of_Finland.svg.png?20230220191416",
  },
  CZE: {
    name: "Czech Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/1599px-Flag_of_the_Czech_Republic.svg.png?20110920204324",
  },
  RUS: {
    name: "Russia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/1599px-Flag_of_Russia.svg.png?20120812011549",
  },
  BGR: {
    name: "Bulgaria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Bulgaria.svg/1600px-Flag_of_Bulgaria.svg.png?20190420140512",
  },
  DEN: {
    name: "Denmark",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/1024px-Flag_of_Denmark.svg.png?20230220190747",
  },
  SUI: {
    name: "Switzerland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/1024px-Flag_of_Switzerland.svg.png?20191016012602",
  },
  CHE: {
    name: "Switzerland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/1024px-Flag_of_Switzerland.svg.png?20191016012602",
  },
  SVK: {
    name: "Slovakia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Slovakia.svg/1599px-Flag_of_Slovakia.svg.png?20221214030619",
  },
  UZB: {
    name: "Uzbekistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/1600px-Flag_of_Uzbekistan.svg.png?20230421060058",
  },
  BEL: {
    name: "Belarus",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/1600px-Flag_of_Belarus.svg.png?20230207071054",
  },
  AUS: {
    name: "Australia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Australia.svg/1600px-Flag_of_Australia.svg.png?20211007161007",
  },
  AUT: {
    name: "Austria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/1599px-Flag_of_Austria.svg.png?20220412141234",
  },
  GBR: {
    name: "United Kingdom",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/1600px-Flag_of_the_United_Kingdom_%283-5%29.svg.png?20230715230526",
  },
  DEU: {
    name: "Germany",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/510px-Flag_of_Germany.svg.png",
  },
  LAT: {
    name: "Latvia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/1600px-Flag_of_Latvia.svg.png?20240509004811",
  },
  FRA: {
    name: "France",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/1600px-Flag_of_Latvia.svg.png?20240509004811",
  },
  NLD: {
    name: "Netherlands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/255px-Flag_of_the_Netherlands.svg.png",
  },
  ZAF: {
    name: "South Africa",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Flag_of_South_Africa.svg/125px-Flag_of_South_Africa.svg.png",
  },
  LVA: {
    name: "Latvia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/125px-Flag_of_Latvia.svg.png",
  },
  KAZ: {
    name: "Kazakhstan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Kazakhstan.svg/135px-Flag_of_Kazakhstan.svg.png",
  },
  GER: {
    name: "Germany",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/125px-Flag_of_Germany.svg.png",
  },
  SVN: {
    name: "Slovenia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/Flag_of_Slovenia.svg/125px-Flag_of_Slovenia.svg.png",
  },
  NOR: {
    name: "Norway",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/125px-Flag_of_Norway.svg.png",
  },
  DNK: {
    name: "Denmark",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/120px-Flag_of_Denmark.svg.png",
  },
  POL: {
    name: "Poland",
    flag: "https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/125px-Flag_of_Poland.svg.png",
  },
  BLR: {
    name: "Belarus",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/125px-Flag_of_Belarus.svg.png",
  },
};

export function getNationalityName(code) {
  const info = nationalityMap[code];
  return info ? info.name : code; // fallback: return the code if not found
}

export function getFlagSrc(code) {
  const info = nationalityMap[code];
  return info ? info.flag : "";  // fallback: empty string if not found
}