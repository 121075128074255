
import React, { useState, useEffect } from "react";
import {Link, useSearchParams} from "react-router-dom";
import { MultiSelectPlayerFilters } from "./MultiSelectPlayerFilters";
import {RenderGameList} from "../pages/TeamGamesPage";
import styles from "../styles/PlayerComparison.module.css";


// Simple loading spinner (optional styling)
function LoadingSpinner() {
  return <div className={styles.spinner}></div>;
}

// const statKeys = [
//   { key: "toi", label: "TOI", invert: false },
//   { key: "xg_scored", label: "xG Scored", invert: false },
//   { key: "xg_scored_60", label: "xG Scored/60", invert: false },
//   { key: "xg_for", label: "xG For", invert: false },
//   { key: "xg_for_60", label: "xG For/60", invert: false },
//   { key: "xg_share", label: "xG Share", invert: false },
//   { key: "goals_scored", label: "Goals Scored", invert: false },
//   { key: "goals_on_ice_total", label: "Goals For", invert: false },
//   { key: "primary_assists", label: "Primary Assists", invert: false },
//   { key: "secondary_assists", label: "Secondary Assists", invert: false },
//   { key: "xg_ag", label: "xG Against", invert: true },  // Inverted
//   { key: "xg_ag_60", label: "xG Against/60", invert: true },  // Inverted
//   { key: "goals_ag_on_ice_total", label: "Goals Against", invert: true },  // Inverted
// ];

const offenseExpectedStatKeys = [
  // { key: "xg_for", label: "xG For", invert: false },
  { key: "xg_for_60", label: "xG For/60", invert: false },
  // { key: "xg_share", label: "xG Share", invert: false },
  // { key: "xg_scored", label: "xG Scored", invert: false },
  { key: "xg_scored_60", label: "xG Scored/60", invert: false },
];

const defenseExpectedStatKeys = [
  // { key: "xg_ag", label: "xG Against", invert: true },
  { key: "xg_ag_60", label: "xG Against/60", invert: true },
];

const offenseRealStatKeys = [
  { key: "goals_scored", label: "Goals Scored", invert: false },
  { key: "goals_on_ice_total", label: "Goals For", invert: false },
  // { key: "primary_assists", label: "Primary Assists", invert: false },
  // { key: "secondary_assists", label: "Secondary Assists", invert: false },
];

const defenseRealStatKeys = [
  { key: "goals_ag_on_ice_total", label: "Goals Against", invert: true },
];

const simpleStatKeys = [
  { key: "toi", label: "Shared EV TOI", invert: false },
  { key: "xg_scored_60", label: "xG Scored/60", invert: false },
  { key: "goals_scored_60", label: "Goals Scored/60", invert: false },
  { key: "assists_60", label: "Assists/60", invert: false },
  { key: "xg_for_60", label: "xG For/60", invert: false },
  { key: "gf_60", label: "Goals For/60", invert: false },
  { key: "xg_ag_60", label: "xG Against/60", invert: true },
  { key: "ga_60", label: "Goals Against/60", invert: true },
];

const timeOnIceStatKeys = [
  { key: "toi", label: "TOI", invert: false },
];

function toPascalCase(statKey) {
  return statKey
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
}

function calculateStatPercentages(num1, num2, invert = false) {
  num1 = parseFloat(num1);
  num2 = parseFloat(num2);

  if (num1 === 0 && num2 === 0) {
    return [50, 50];
  }

  const total = num1 + num2;
  let percentage1 = (num1 / total) * 100;
  let percentage2 = (num2 / total) * 100;

  if (invert) {
    percentage1 = 100 - percentage1;
    percentage2 = 100 - percentage2;
  }

  return [percentage1, percentage2];
}


function PlayerComparisonBars({ player1Data, player2Data, comparisonType }) {
  useEffect(() => {
    if (!player1Data || !player2Data) return;

    [...simpleStatKeys, ...defenseExpectedStatKeys, ...offenseRealStatKeys, ...defenseRealStatKeys, ...timeOnIceStatKeys].forEach(({ key, invert }) => {
      const p1Val = key === "toi" ? player1Data.toi ?? 0 : player1Data[key] ?? 0;
      const p2Val = key === "toi" ? player2Data.toi ?? 0 : player2Data[key] ?? 0;

      const [p1Pct, p2Pct] = calculateStatPercentages(p1Val, p2Val, invert);

      const pascalStat = toPascalCase(key);
      const p1Element = document.getElementById(`player1${pascalStat}Bar`);
      const p2Element = document.getElementById(`player2${pascalStat}Bar`);

      if (p1Element) p1Element.style.width = `${p1Pct}%`;
      if (p2Element) p2Element.style.width = `${p2Pct}%`;
    });
  }, [player1Data, player2Data]);

  const renderStatBars = (statGroup, title) => (
    <>
      <h3 className={styles.subtitle}>{title}</h3>
      {statGroup.map(({ key, label }) => {
        const pascalStat = toPascalCase(key);
        const p1DisplayValue = key === "toi" ? player1Data.toi_str : player1Data[key];
        const p2DisplayValue = key === "toi" ? player2Data.toi_str : player2Data[key];

        return (
          <div key={key}>
            <div className={styles.loadingBarTitle}>{label}</div>
            <div className={styles.loadingBarContainer}>
              <div className={styles.loadingBar}>
                <div
                  className={styles.teamLeftXgPercentage}
                  id={`player1${pascalStat}Bar`}
                  style={{ backgroundColor: player1Data.stat_color }}
                >
                  <span className={styles.xgText}>{p1DisplayValue}</span>
                </div>
                <div
                  className={styles.teamRightXgPercentage}
                  id={`player2${pascalStat}Bar`}
                  style={{ backgroundColor: player2Data.stat_color }}
                >
                  <span className={styles.xgText}>{p2DisplayValue}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );

  return (
    <div>
      {/* Player Info Section */}
      <div className={styles.scoreContainer}>
        {/* Left Side - Player 1 */}
        <div className={styles.playerInfo}>
          <Link to={`/players/${player1Data.player_id}`}>
            <img
              className={styles.playerMugshot}
              src={`https://assets.nhle.com/mugs/nhl/${player1Data.season}/${player1Data.playing_for}/${player1Data.player_id}.png`}
              alt={`${player1Data.name} mugshot`}
            />
          </Link>
          <h4 className={styles.playerName}>
            {comparisonType === "With / Without (teammate comparison)"
              ? `${player1Data.name} Without ❌ ${player2Data.name}`
              : player1Data.name}
          </h4>
        </div>

        {/* Middle VS Text */}
        <div className={styles.vsText}>
          <h3>{comparisonType === "With / Without (teammate comparison)" ? "" : "VS"}</h3>
        </div>

        {/* Right Side - Player 2 or Both (With / Without) */}
        <div className={styles.playerInfo}>
          {comparisonType === "With / Without (teammate comparison)" ? (
            <div className={styles.dualMugshots}>
              <Link to={`/players/${player1Data.player_id}`}>
                <img
                  className={styles.playerMugshot}
                  src={`https://assets.nhle.com/mugs/nhl/${player1Data.season}/${player1Data.playing_for}/${player1Data.player_id}.png`}
                  alt={`${player1Data.name} mugshot`}
                />
              </Link>
              <Link to={`/players/${player2Data.player_id}`}>
                <img
                  className={styles.playerMugshot}
                  src={`https://assets.nhle.com/mugs/nhl/${player2Data.season}/${player2Data.playing_for}/${player2Data.player_id}.png`}
                  alt={`${player2Data.name} mugshot`}
                />
              </Link>
            </div>
          ) : (
            <Link to={`/players/${player2Data.player_id}`}>
              <img
                className={styles.playerMugshot}
                src={`https://assets.nhle.com/mugs/nhl/${player2Data.season}/${player2Data.playing_for}/${player2Data.player_id}.png`}
                alt={`${player2Data.name} mugshot`}
              />
            </Link>
          )}
          <h4 className={styles.playerName}>
            {comparisonType === "With / Without (teammate comparison)"
              ? `${player1Data.name} With 🤝 ${player2Data.name}`
              : player2Data.name}
          </h4>
        </div>
      </div>

      <h3 className={styles.subtitle}>
        Showing even strength analytics from {player1Data.number_of_games} games
        {/*where {player1Data.playing_for} had a record of {player1Data.record}*/}
      </h3>

        {/* Subtitle and grouped stat bars */}
        {renderStatBars(simpleStatKeys, "")}
        {/*{renderStatBars(offenseExpectedStatKeys, "Expected Offense Stats")}*/}
        {/*{renderStatBars(defenseExpectedStatKeys, "Expected Defense Stats")}*/}
        {/*{renderStatBars(offenseRealStatKeys, "Real Offense Stats")}*/}
        {/*{renderStatBars(defenseRealStatKeys, "Real Defense Stats")}*/}
      </div>
  );
}

// function ComparePlayers() {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const initialPlayer1 = searchParams.get("player1") || "";
//   const initialPlayer2 = searchParams.get("player2") || "";
//   const initialType = searchParams.get("type") || "";
//
//   // Selection states
//   const [selectedPlayer1, setSelectedPlayer1] = useState(initialPlayer1);
//   const [selectedPlayer2, setSelectedPlayer2] = useState(initialPlayer2);
//   const [selectedComparisonType, setSelectedComparisonType] = useState(initialType); // Dropdown value
//   const [comparisonType, setComparisonType] = useState(initialType); // Used for fetching/rendering
//   const [comparedPlayerData, setComparedPlayerData] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//
//   // Update URL params
//   useEffect(() => {
//     setSearchParams((prevParams) => {
//       const newParams = new URLSearchParams(prevParams.toString());
//       if (selectedPlayer1) {
//         newParams.set("player1", selectedPlayer1);
//       } else {
//         newParams.delete("player1");
//       }
//       if (selectedPlayer2) {
//         newParams.set("player2", selectedPlayer2);
//       } else {
//         newParams.delete("player2");
//       }
//       if (comparisonType) {
//         newParams.set("type", comparisonType);
//       } else {
//         newParams.delete("type");
//       }
//       return newParams;
//     });
//   }, [selectedPlayer1, selectedPlayer2, comparisonType, setSearchParams]);
//
//   // Clear previous comparison data when any selection changes
//   useEffect(() => {
//     setComparedPlayerData(null);
//   }, [selectedPlayer1, selectedPlayer2, selectedComparisonType]);
//
//   // Fetch data when the "Compare" button is clicked
//   async function handleCompareClick() {
//     if (!selectedPlayer1 || !selectedPlayer2 || !selectedComparisonType) return;
//     setIsLoading(true);
//
//     try {
//       const url = `/api/players/compare/?player1=${selectedPlayer1}&player2=${selectedPlayer2}&type=${encodeURIComponent(
//         selectedComparisonType
//       )}`;
//       const res = await fetch(url);
//       const data = await res.json();
//       setComparedPlayerData(data);
//       setComparisonType(selectedComparisonType); // Only update when data is successfully fetched
//     } catch (error) {
//       console.error("Error fetching comparison data:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }
//
//   // Automatically fetch if query params are present initially
//   useEffect(() => {
//     if (selectedPlayer1 && selectedPlayer2 && initialType) {
//       handleCompareClick();
//     }
//     // eslint-disable-next-line
//   }, []);
//
//   const canCompare = selectedPlayer1 && selectedPlayer2 && selectedComparisonType;
//
//   // Extract each player's data after comparison, if available
//   const player1Data =
//     comparedPlayerData && comparedPlayerData[selectedPlayer1.toString()];
//   const player2Data =
//     comparedPlayerData && comparedPlayerData[selectedPlayer2.toString()];
//
//   return (
//     <div className={styles.pageContainer}>
//       <h2 className={styles.title}>Compare Players</h2>
//
//       {/* Filter Row */}
//       <div className={styles.filterContainer}>
//         <MultiSelectPlayerFilters
//           label="Compare"
//           onPlayerSelected={(p1Id) => setSelectedPlayer1(p1Id)}
//         />
//         <MultiSelectPlayerFilters
//           label="To"
//           onPlayerSelected={(p2Id) => setSelectedPlayer2(p2Id)}
//         />
//       </div>
//
//       {/* Comparison Type Selector + Compare Button */}
//       {selectedPlayer1 && selectedPlayer2 && (
//         <div className={styles.compareButtonContainer}>
//           <select
//             className={styles.timeframeSelect}
//             value={selectedComparisonType}
//             onChange={(e) => setSelectedComparisonType(e.target.value)}
//           >
//             <option value="">-- Select a Comparison --</option>
//             <option value="Head to Head">Head to Head</option>
//             <option value="With / Without (teammate comparison)">
//               With / Without (teammate comparison)
//             </option>
//           </select>
//
//           <button
//             className={styles.compareButton}
//             onClick={handleCompareClick}
//             disabled={!canCompare}
//           >
//             COMPARE
//           </button>
//         </div>
//       )}
//
//       {/* Show loading spinner while fetching */}
//       {isLoading && (
//         <div className={styles.loadingContainer}>
//           <LoadingSpinner />
//         </div>
//       )}
//
//       {/* Show comparison results if data is available */}
//       {!isLoading && comparedPlayerData !== null && (
//         <>
//           {Object.keys(comparedPlayerData).length === 0 ? (
//             // If the API returned an empty object, display a message
//             <p>No {comparisonType} games found</p>
//           ) : (
//             // Otherwise, render the comparison components
//             <>
//               <PlayerComparisonBars
//                 player1Data={player1Data}
//                 player2Data={player2Data}
//                 comparisonType={comparisonType}
//               />
//
//               {/* Games Analyzed Section */}
//               {comparedPlayerData.game_info && (
//                 <>
//                   <h2 className={styles.title}>Games Analyzed</h2>
//                   <div className="row">
//                     <div className="text-center col-me-6 col-sma-10 mc-auto p-0">
//                       <div className="card p-3 text-center">
//                         <ul className="list-group">
//                           <RenderGameList
//                             games={comparedPlayerData.game_info}
//                             teamAbb={player1Data?.playing_for}
//                           />
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </>
//           )}
//         </>
//       )}
//     </div>
//   );
// }

function ComparePlayersSharedIce() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPlayer1 = searchParams.get("player1") || "";
  const initialPlayer2 = searchParams.get("player2") || "";
  const initialType = searchParams.get("type") || "";

  // Selection states
  const [selectedPlayer1, setSelectedPlayer1] = useState(initialPlayer1);
  const [selectedPlayer2, setSelectedPlayer2] = useState(initialPlayer2);
  const [selectedComparisonType, setSelectedComparisonType] = useState(initialType); // Dropdown value
  const [comparisonType, setComparisonType] = useState(initialType); // Used for fetching/rendering
  const [comparedPlayerData, setComparedPlayerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Update URL params
  useEffect(() => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams.toString());
      if (selectedPlayer1) {
        newParams.set("player1", selectedPlayer1);
      } else {
        newParams.delete("player1");
      }
      if (selectedPlayer2) {
        newParams.set("player2", selectedPlayer2);
      } else {
        newParams.delete("player2");
      }
      if (comparisonType) {
        newParams.set("type", comparisonType);
      } else {
        newParams.delete("type");
      }
      return newParams;
    });
  }, [selectedPlayer1, selectedPlayer2, comparisonType, setSearchParams]);

  // Clear previous comparison data when any selection changes
  useEffect(() => {
    setComparedPlayerData(null);
  }, [selectedPlayer1, selectedPlayer2, selectedComparisonType]);

  // Fetch data when the "Compare" button is clicked
  async function handleCompareClick() {
    if (!selectedPlayer1 || !selectedPlayer2 || !selectedComparisonType) return;
    setIsLoading(true);

    try {
      const url = `/api/players/compare/?player1=${selectedPlayer1}&player2=${selectedPlayer2}&type=${encodeURIComponent(
        selectedComparisonType
      )}`;
      const res = await fetch(url);
      const data = await res.json();
      setComparedPlayerData(data);
      setComparisonType(selectedComparisonType); // Only update when data is successfully fetched
    } catch (error) {
      console.error("Error fetching comparison data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  // Automatically fetch if query params are present initially
  useEffect(() => {
    if (selectedPlayer1 && selectedPlayer2 && initialType) {
      handleCompareClick();
    }
    // eslint-disable-next-line
  }, []);

  const canCompare = selectedPlayer1 && selectedPlayer2 && selectedComparisonType;

  // Extract each player's data after comparison, if available
  const player1Data =
    comparedPlayerData && comparedPlayerData[selectedPlayer1.toString()];
  const player2Data =
    comparedPlayerData && comparedPlayerData[selectedPlayer2.toString()];

  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.title}>Compare Players</h2>

      {/* Filter Row */}
      <div className={styles.filterContainer}>
        <MultiSelectPlayerFilters
          label="Compare"
          onPlayerSelected={(p1Id) => setSelectedPlayer1(p1Id)}
        />
        <MultiSelectPlayerFilters
          label="To"
          onPlayerSelected={(p2Id) => setSelectedPlayer2(p2Id)}
        />
      </div>

      {/* Comparison Type Selector + Compare Button */}
      {selectedPlayer1 && selectedPlayer2 && (
        <div className={styles.compareButtonContainer}>
          <select
            className={styles.timeframeSelect}
            value={selectedComparisonType}
            onChange={(e) => setSelectedComparisonType(e.target.value)}
          >
            <option value="">-- Select a Comparison --</option>
            <option value="Head to Head">Head to Head</option>
            <option value="With / Without (teammate comparison)">
              With / Without (teammate comparison)
            </option>
          </select>

          <button
            className={styles.compareButton}
            onClick={handleCompareClick}
            disabled={!canCompare}
          >
            COMPARE
          </button>
        </div>
      )}

      {/* Show loading spinner while fetching */}
      {isLoading && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      )}

      {/* Show comparison results if data is available and valid for current selections */}
      {/* Show comparison results if data is available */}
{!isLoading && comparedPlayerData !== null ? (
  Object.keys(comparedPlayerData).length === 0 ? (
    // If the API returned an empty object, display a message
    <p>No {comparisonType} games found</p>
  ) : (
    // Only render if compared data exists for both players
    (comparedPlayerData[selectedPlayer1.toString()] && comparedPlayerData[selectedPlayer2.toString()]) ? (
      <>
        <PlayerComparisonBars
          player1Data={comparedPlayerData[selectedPlayer1.toString()]}
          player2Data={comparedPlayerData[selectedPlayer2.toString()]}
          comparisonType={comparisonType}
        />

        {/* Games Analyzed Section */}
        {comparedPlayerData.game_info && (
          <>
            <h2 className={styles.title}>Games Analyzed</h2>
            <div className="row">
              <div className="text-center col-me-6 col-sma-10 mc-auto p-0">
                <div className="card p-3 text-center">
                  <ul className="list-group">
                    <RenderGameList
                      games={comparedPlayerData.game_info}
                      teamAbb={comparedPlayerData[selectedPlayer1.toString()]?.playing_for}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    ) : null // Render nothing if data for both players isn't present
  )
) : null}
    </div>
  );
}
export default ComparePlayersSharedIce;