// // import React, { useState } from 'react';
// // import {
// //   LineChart,
// //   Line,
// //   XAxis,
// //   YAxis,
// //   Tooltip,
// //   CartesianGrid,
// //   ResponsiveContainer,
// //   Legend,
// // } from 'recharts';
// // import styles from "../styles/PlayerRatingsTable.module.css";
// //
// // const AverageGameRatingSnake = ({ trendingData, season, playerNames }) => {
// //   // All hooks must be called here unconditionally.
// //   const [hiddenPlayers, setHiddenPlayers] = useState(new Set());
// //   const [hoveredPlayer, setHoveredPlayer] = useState(null);
// //
// //   if (!trendingData || trendingData.length === 0) {
// //     return <div>No data available</div>;
// //   }
// //
// //   // Reserved keys that are not player IDs.
// //   const reservedKeys = new Set(['date', 'winner', 'loser', 'winner_score', 'loser_score']);
// //
// //   // Extract player keys from the first data row.
// //   const firstRow = trendingData[0];
// //   const playerKeys = Object.keys(firstRow).filter(key => !reservedKeys.has(key));
// //
// //   // Define colors for lines.
// //   const colors = [
// //     "#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#0088FE",
// //     "#00C49F", "#FFBB28", "#FF8042", "#A28FD0", "#D0A28F",
// //   ];
// //
// //   // Handler for toggling a player's visibility.
// //   const handleLegendClick = (playerKey) => {
// //     const newHidden = new Set(hiddenPlayers);
// //     if (newHidden.has(playerKey)) {
// //       newHidden.delete(playerKey);
// //     } else {
// //       newHidden.add(playerKey);
// //     }
// //     setHiddenPlayers(newHidden);
// //   };
// //
// //   // Custom legend renderer.
// //   const renderCustomizedLegend = (props) => {
// //     const { payload } = props;
// //     return (
// //       <ul style={{ listStyle: 'none', display: 'flex', flexWrap: 'wrap', padding: 0 }}>
// //         {payload.map((entry) => {
// //           const playerKey = entry.dataKey;
// //           const isHidden = hiddenPlayers.has(playerKey);
// //           const style = {
// //             marginRight: 10,
// //             cursor: 'pointer',
// //             opacity: isHidden ? 0.5 : 1,
// //             color: entry.color,
// //           };
// //           return (
// //             <li key={`item-${playerKey}`} onClick={() => handleLegendClick(playerKey)} style={style}>
// //               {playerNames[playerKey] || playerKey}
// //             </li>
// //           );
// //         })}
// //       </ul>
// //     );
// //   };
// //
// //   // Handlers for hover highlighting.
// //   const handleLineMouseEnter = (playerKey) => setHoveredPlayer(playerKey);
// //   const handleLineMouseLeave = () => setHoveredPlayer(null);
// //
// //   return (
// //       <ResponsiveContainer width="100%" height={600}>
// //         <LineChart data={trendingData} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
// //           <CartesianGrid strokeDasharray="3 3" />
// //           <XAxis
// //             dataKey="date"
// //             tick={{ fontSize: 12 }}
// //             label={{ value: 'Game Date', position: 'insideBottom', dy: 20, textAnchor: 'middle' }}
// //           />
// //           <YAxis
// //             tick={{ fontSize: 12 }}
// //             label={{ value: 'Average Game Rating', angle: -90, position: 'insideLeft', dx: -10 }}
// //           />
// //           <Tooltip />
// //           <Legend content={renderCustomizedLegend} />
// //           {playerKeys.map((playerKey, index) => {
// //             if (hiddenPlayers.has(playerKey)) return null;
// //             const strokeWidth = hoveredPlayer === playerKey ? 4 : 2;
// //             return (
// //               <Line
// //                 key={playerKey}
// //                 type="monotone"
// //                 dataKey={playerKey}
// //                 name={playerNames[playerKey] || playerKey}
// //                 stroke={colors[index % colors.length]}
// //                 strokeWidth={strokeWidth}
// //                 dot={false}
// //                 onMouseEnter={() => handleLineMouseEnter(playerKey)}
// //                 onMouseLeave={handleLineMouseLeave}
// //               />
// //             );
// //           })}
// //         </LineChart>
// //       </ResponsiveContainer>
// //   );
// // };
// //
// // export default AverageGameRatingSnake;
// import React, { useState } from 'react';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
// } from 'recharts';
//
// const CustomTooltip = ({ active, payload, label, activeLine, playerNames }) => {
//   // Only display tooltip if active, payload exists, and we have a hovered (active) line.
//   if (active && payload && payload.length && activeLine) {
//     // Filter payload to include only the active line's data.
//     const filteredPayload = payload.filter((p) => p.dataKey === activeLine);
//     if (!filteredPayload.length) return null;
//     const value = filteredPayload[0].value;
//     // Use the player's full name if available.
//     const displayName = playerNames[activeLine] || activeLine;
//     return (
//       <div
//         style={{
//           backgroundColor: 'rgba(255,255,255,0.9)',
//           padding: '10px',
//           border: '1px solid #ccc',
//           borderRadius: '4px',
//           pointerEvents: 'none',
//           zIndex: 9999,
//         }}
//       >
//         <p>{`${displayName}: ${value}`}</p>
//       </div>
//     );
//   }
//   return null;
// };
//
// const CumulativeGameRatingSnake = ({ trendingData, season, playerNames }) => {
//   const [activeLine, setActiveLine] = useState(null);
//
//   if (!trendingData || trendingData.length === 0) {
//     return <div>No data available</div>;
//   }
//
//   // Reserved keys that are not player IDs.
//   const reservedKeys = new Set(['date', 'winner', 'loser', 'winner_score', 'loser_score', 'color']);
//   const firstRow = trendingData[0];
//   // Extract player keys: these are the keys that are not reserved.
//   const playerKeys = Object.keys(firstRow).filter((key) => !reservedKeys.has(key));
//
//   // Use a constant color for all lines (from trendingData[0].color, falling back to a default)
//   const lineColor = firstRow.color || '#595959';
//   const highlightColor = firstRow.highlight || "#8884d8";
//
//   return (
//     <ResponsiveContainer width="100%" height={1200}>
//       <LineChart
//         data={trendingData}
//         margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis
//           dataKey="date"
//           tick={{ fontSize: 12 }}
//           label={{
//             value: 'Game Date',
//             position: 'insideBottom',
//             dy: 20,
//             textAnchor: 'middle',
//           }}
//         />
//         <YAxis
//           tick={{ fontSize: 12 }}
//           label={{
//             value: 'Average Game Rating',
//             angle: -90,
//             position: 'insideLeft',
//             dx: -10,
//           }}
//         />
//         <Tooltip
//           content={({ active, payload, label }) => (
//             <CustomTooltip
//               active={active}
//               payload={payload}
//               label={label}
//               activeLine={activeLine}
//               playerNames={playerNames}
//             />
//           )}
//         />
//         {playerKeys.map((playerKey, index) => {
//             const isActive = activeLine === playerKey;
//           // Only render the line if it is not hidden.
//           // (Since we removed the legend, we’re not toggling visibility here.)
//           // We add mouse events to update activeLine for tooltip highlighting.
//           return (
//             <Line
//               key={playerKey}
//               type="monotone"
//               dataKey={playerKey}
//               name={playerNames[playerKey] || playerKey}
//               stroke={isActive ? highlightColor : lineColor}
//               strokeWidth={activeLine === playerKey ? 5 : 2}
//               dot={false}
//               onMouseEnter={() => setActiveLine(playerKey)}
//               onMouseLeave={() => setActiveLine(null)}
//               activeDot={false} // disable built-in activeDot behavior
//             />
//           );
//         })}
//       </LineChart>
//     </ResponsiveContainer>
//   );
// };
//
// export default CumulativeGameRatingSnake;
//
//
// import React, { useState } from 'react';
// import {
//   ResponsiveContainer,
//   ComposedChart,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Line,
//   Area,
// } from 'recharts';

// Helper to format date as MM/DD/YYYY.
// const formatDate = (dateStr) => {
//   const date = new Date(dateStr);
//   const month = ("0" + (date.getMonth() + 1)).slice(-2);
//   const day = ("0" + date.getDate()).slice(-2);
//   const year = date.getFullYear();
//   return `${month}/${day}/${year}`;
// };
//
// // Custom tooltip that shows nested stat value and extra game details if present.
// const CustomTooltip = ({ active, payload, label, nameMapping, yAxisLabel }) => {
//   if (active && payload && payload.length) {
//     // dataKey is expected to be in the form "teams.DAL" or "players.XYZ"
//     const fullKey = payload[0].dataKey;
//     const keyParts = fullKey.split('.');
//     const lineKey = keyParts[1]; // e.g. "DAL"
//     const displayName = nameMapping ? (nameMapping[lineKey] || lineKey) : lineKey;
//     const value = payload[0].value;
//     const formattedDate = formatDate(label);
//     let extraInfo = '';
//
//     // Check for extra game-level details.
//     const data = payload[0].payload;
//     if (
//       data.winner !== undefined &&
//       data.winner_score !== undefined &&
//       data.loser_score !== undefined &&
//       data.final_period !== undefined
//     ) {
//       extraInfo = `${data.winner} ${data.winner_score} — ${data.loser_score} ${data.loser}`;
//       if (data.final_period === 'OT' || data.final_period === 'SO') {
//         extraInfo += ` (${data.final_period})`;
//       }
//     }
//     return (
//       <div
//         style={{
//           backgroundColor: 'white',
//           border: '1px solid #ccc',
//           padding: '10px',
//         }}
//       >
//         <p style={{ margin: 0 }}>
//           {`${displayName} ${yAxisLabel}: ${value}`}
//         </p>
//         {extraInfo && <p style={{ margin: 0 }}>{extraInfo}</p>}
//         <p style={{ margin: 0 }}>{formattedDate}</p>
//       </div>
//     );
//   }
//   return null;
// };
//
// // Custom dot that renders the winner's logo if game-level details exist.
// const renderCustomDot = (props) => {
//   const { cx, cy, payload, stroke } = props;
//   if (
//     payload &&
//     payload.winner !== undefined &&
//     payload.winner_score !== undefined &&
//     payload.loser_score !== undefined &&
//     payload.final_period !== undefined
//   ) {
//     const logoUrl = `https://assets.nhle.com/logos/nhl/svg/${payload.winner}_light.svg`;
//     return (
//       <image href={logoUrl} x={cx - 25} y={cy - 25} width={50} height={50} />
//     );
//   }
//   return <circle cx={cx} cy={cy} r={3} fill={stroke} />;
// };
//
//
// const SnakeChart = (props) => {
//   const [activeLine, setActiveLine] = useState(null);
//   const { data, groupColors, yAxisLabel, playerNames, teamNames } = props;
//   const nameMapping = playerNames || teamNames || {};
//
//   if (!data || data.length === 0) {
//     return <div>Loading chart...</div>;
//   }
//
//   const CenteredYAxisLabel = ({viewBox, value}) => {
//     const {x, y, height} = viewBox;
//     // Adjust the horizontal offset as needed.
//     const cx = x + 10;
//     // Center vertically within the axis area.
//     const cy = y + height / 2;
//     return (
//       <text
//         x={cx}
//         y={cy}
//         fill="grey"
//         textAnchor="middle"
//         dominantBaseline="middle"
//         transform={`rotate(-90, ${cx}, ${cy})`}
//         style={{ fontSize: 15 }}
//       >
//         {value}
//       </text>
//     );
//   };
//
//   // Determine whether the data contains "players" or "teams"
//   const isTeamGrouping = data[0].hasOwnProperty('teams');
//   const statDataKey = isTeamGrouping ? 'teams' : 'players';
//   const statKeys = Object.keys(data[0][statDataKey]);
//
//   // Determine if there is only one stat line
//   const singleLine = statKeys.length === 1;
//
//   // Compute tick values for the XAxis.
//   const tickStep = Math.max(1, Math.ceil(data.length / 10));
//   let ticks = data.filter((_, index) => index % tickStep === 0).map((d) => d.date);
//   if (ticks[ticks.length - 1] !== data[data.length - 1].date) {
//     ticks.push(data[data.length - 1].date);
//   }
//
//   return (
//     <ResponsiveContainer width="100%" height={600}>
//       <ComposedChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: 50 }}>
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis
//           dataKey="date"
//           tickFormatter={formatDate}
//           ticks={ticks}
//           label={{ value: 'Date', position: 'insideBottom', dy: 20, textAnchor: 'middle' }}
//         />
//         <YAxis
//           label={
//             <CenteredYAxisLabel
//               value={yAxisLabel}
//             />
//           }
//         />
//         <Tooltip content={<CustomTooltip nameMapping={nameMapping} yAxisLabel={yAxisLabel} />} />
//
//         {/* Render shading only if there is a single line */}
//         {singleLine &&
//           statKeys.map((key) => (
//             <Area
//               key={`area-${key}`}
//               type="monotone"
//               dataKey={`${statDataKey}.${key}`}
//               baseValue={0}
//               stroke="none"
//               fill={groupColors[key].color}
//               fillOpacity={0.3}
//               connectNulls
//             />
//           ))
//         }
//
//         {/* Render the Lines */}
//         {statKeys.map((key) => (
//           <Line
//             key={key}
//             type="monotone"
//             dataKey={`${statDataKey}.${key}`}
//             name={nameMapping[key] || key}
//             stroke={activeLine === key ? groupColors[key].highlight : groupColors[key].color}
//             strokeWidth={activeLine === key ? 4 : 2}
//             // Render custom dot only if there's a single line
//             dot={singleLine ? renderCustomDot : undefined}
//             activeDot={false}
//             connectNulls
//             onMouseEnter={() => setActiveLine(key)}
//             onMouseLeave={() => setActiveLine(null)}
//           />
//         ))}
//       </ComposedChart>
//     </ResponsiveContainer>
//   );
// };
//
// export default SnakeChart;

// -- This is like second iteration when we lost some of the initial functionality like shading, but this puts the two mugs nicely.
// import React, { useState } from 'react';
// import {
//   ResponsiveContainer,
//   ComposedChart,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Line,
// } from 'recharts';
// import styles from "../styles/CompareSnakes.module.css";
//
// // Helper to format date as MM/DD/YYYY.
// const formatDate = (dateStr) => {
//   const date = new Date(dateStr);
//   const month = ("0" + (date.getMonth() + 1)).slice(-2);
//   const day = ("0" + date.getDate()).slice(-2);
//   const year = date.getFullYear();
//   return `${month}/${day}/${year}`;
// };
//
// // Custom Tooltip: When there are exactly two lines, always display both values.
// const CustomTooltip = ({
//   active,
//   payload,
//   label,
//   nameMapping,
//   yAxisLabel,
//   isTwoLines,
//   groupColors,
//   higherKey,
//   lowerKey,
// }) => {
//   if (active && payload && payload.length) {
//     if (isTwoLines && payload.length >= 2 && higherKey && lowerKey) {
//       // Create a mapping from player key to payload item.
//       const payloadMap = {};
//       payload.forEach(item => {
//         const key = item.dataKey.split('.')[1];
//         payloadMap[key] = item;
//       });
//       const itemHigher = payloadMap[higherKey];
//       const itemLower = payloadMap[lowerKey];
//       if (itemHigher && itemLower) {
//         const displayNameHigher = nameMapping ? (nameMapping[higherKey] || higherKey) : higherKey;
//         const displayNameLower = nameMapping ? (nameMapping[lowerKey] || lowerKey) : lowerKey;
//         return (
//           <div
//             style={{
//               backgroundColor: 'rgba(255,255,255,0.9)',
//               padding: '10px',
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//               pointerEvents: 'none',
//               zIndex: 9999,
//             }}
//           >
//             <p style={{ margin: 0, color: groupColors[higherKey]?.color || "#000" }}>
//               {`${displayNameHigher}: ${itemHigher.value}`}
//             </p>
//             <p style={{ margin: 0, color: groupColors[lowerKey]?.color || "#000" }}>
//               {`${displayNameLower}: ${itemLower.value}`}
//             </p>
//             <p style={{ margin: 0 }}>{formatDate(label)}</p>
//           </div>
//         );
//       }
//     } else {
//       const entry = payload[0];
//       const key = entry.dataKey.split('.')[1];
//       const displayName = nameMapping ? (nameMapping[key] || key) : key;
//       return (
//         <div
//           style={{
//             backgroundColor: 'rgba(255,255,255,0.9)',
//             padding: '10px',
//             border: '1px solid #ccc',
//             borderRadius: '4px',
//             pointerEvents: 'none',
//             zIndex: 9999,
//           }}
//         >
//           <p style={{ margin: 0, color: groupColors[key]?.color || "#000" }}>
//             {`${displayName}: ${entry.value}`}
//           </p>
//           <p style={{ margin: 0 }}>{formatDate(label)}</p>
//         </div>
//       );
//     }
//   }
//   return null;
// };
//
// const SnakeChart = (props) => {
//   const [activeLine, setActiveLine] = useState(null);
//   const { data, groupColors, yAxisLabel, playerNames, teamNames, season, playerInfo } = props;
//   const nameMapping = playerNames || teamNames || {};
//
//   if (!data || data.length === 0) {
//     return <div>Loading chart...</div>;
//   }
//
//   // Determine whether we are grouping by players or teams.
//   const isTeamGrouping = data[0].hasOwnProperty('teams');
//   const statDataKey = isTeamGrouping ? 'teams' : 'players';
//
//   // Instead of using only the first data point, compute the union of keys across all data points.
//   const statKeys = Array.from(new Set(data.flatMap(d => Object.keys(d[statDataKey]))));
//   const isTwoLines = statKeys.length === 2;
//
//   // Compute final values from the last data point.
//   const finalData = data[data.length - 1];
//   const finalValues = statKeys.reduce((acc, key) => {
//     acc[key] = finalData[statDataKey][key];
//     return acc;
//   }, {});
//   let higherKey, lowerKey;
//   if (isTwoLines) {
//     const [key1, key2] = statKeys;
//     if (finalValues[key1] >= finalValues[key2]) {
//       higherKey = key1;
//       lowerKey = key2;
//     } else {
//       higherKey = key2;
//       lowerKey = key1;
//     }
//   }
//
//   // Define mug dimensions (default values; these can be made responsive via CSS)
//   const baseMugSize = 60; // use this for offset calculations only
//   const horizontalOffset = -1 * baseMugSize;
//   // const mugHeight = 50;
//
//   // Custom dot function: renders a default dot; if final data point, adds the mug image.
//   const renderCustomDot = (dotProps) => {
//   const { cx, cy, index, dataLength, dataKey } = dotProps;
//   const defaultDot = <circle cx={cx} cy={cy} r={4} fill="none"/>;
//   if (index !== dataLength - 1 || !playerInfo) {
//     return defaultDot;
//   }
//   // Extract player id from dataKey (expected format: "players.<playerId>")
//   const parts = dataKey.split('.');
//   const key = parts[1];
//   let verticalOffset = 0;
//   if (isTwoLines) {
//     // For the higher value, use full baseMugSize offset (above).
//     // For the lower value, use half the baseMugSize (below).
//     verticalOffset = -baseMugSize;
//   } else {
//     verticalOffset = -baseMugSize;
//   }
//   return (
//     <g>
//       {defaultDot}
//       <image
//         className={styles.mug}
//         href={`https://assets.nhle.com/mugs/nhl/${season}/${playerInfo[key].team_abb}/${key}.png`}
//         x={cx + horizontalOffset}
//         y={cy + verticalOffset}
//       />
//     </g>
//   );
// };
//
//   // Compute tick values for the XAxis.
//   const tickStep = Math.max(1, Math.ceil(data.length / 10));
//   let ticks = data.filter((_, index) => index % tickStep === 0).map((d) => d.date);
//   if (ticks[ticks.length - 1] !== data[data.length - 1].date) {
//     ticks.push(data[data.length - 1].date);
//   }
//
//   return (
//     <ResponsiveContainer width="100%" height={600}>
//       <ComposedChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: 50 }}>
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis
//           dataKey="date"
//           tickFormatter={formatDate}
//           ticks={ticks}
//           label={{ value: 'Date', position: 'insideBottom', dy: 20, textAnchor: 'middle' }}
//         />
//         <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideLeft', dx: -10 }} />
//         <Tooltip
//           content={
//             <CustomTooltip
//               nameMapping={nameMapping}
//               yAxisLabel={yAxisLabel}
//               isTwoLines={isTwoLines}
//               groupColors={groupColors}
//               higherKey={higherKey}
//               lowerKey={lowerKey}
//             />
//           }
//         />
//         {statKeys.map((key) => {
//           const isActive = activeLine === key;
//           return (
//             <Line
//               key={key}
//               type="monotone"
//               dataKey={`${statDataKey}.${key}`}
//               name={nameMapping[key] || key}
//               stroke={isActive ? groupColors[key].highlight : groupColors[key].color}
//               strokeWidth={isActive ? 8 : 4}
//               dot={(dotProps) => renderCustomDot({ ...dotProps, dataLength: data.length, dataKey: `${statDataKey}.${key}` })}
//               activeDot={false}
//               connectNulls
//               onMouseEnter={() => setActiveLine(key)}
//               onMouseLeave={() => setActiveLine(null)}
//             />
//           );
//         })}
//       </ComposedChart>
//     </ResponsiveContainer>
//   );
// };
//
// export default SnakeChart;

import React, { useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  Area, ReferenceLine,
} from 'recharts';
import styles from "../styles/CompareSnakes.module.css";

// Helper to format date as MM/DD/YYYY.
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

// Centered Y-Axis Label as in the old version.
const CenteredYAxisLabel = ({ viewBox, value }) => {
  const { x, y, height } = viewBox;
  // Adjust the horizontal offset as needed.
  const cx = x + 10;
  // Center vertically within the axis area.
  const cy = y + height / 2;
  return (
    <text
      x={cx}
      y={cy}
      fill="grey"
      textAnchor="middle"
      dominantBaseline="middle"
      transform={`rotate(-90, ${cx}, ${cy})`}
      style={{ fontSize: 15 }}
    >
      {value}
    </text>
  );
};

// Custom Tooltip: When there are exactly two lines, always display both values (and for team grouping, show score details).
const CustomTooltip = ({
  active,
  payload,
  label,
  nameMapping,
  yAxisLabel,
  isTwoLines,
  groupColors,
  higherKey,
  lowerKey,
}) => {
  if (active && payload && payload.length) {
    if (isTwoLines && payload.length >= 2 && higherKey && lowerKey) {
      const payloadMap = {};
      payload.forEach(item => {
        const key = item.dataKey.split('.')[1];
        payloadMap[key] = item;
      });
      const itemHigher = payloadMap[higherKey];
      const itemLower = payloadMap[lowerKey];
      if (itemHigher && itemLower) {
        const displayNameHigher = nameMapping ? (nameMapping[higherKey] || higherKey) : higherKey;
        const displayNameLower = nameMapping ? (nameMapping[lowerKey] || lowerKey) : lowerKey;
        return (
          <div
            style={{
              backgroundColor: 'rgba(255,255,255,0.9)',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              pointerEvents: 'none',
              zIndex: 9999,
            }}
          >
            <p style={{ margin: 0, color: groupColors[higherKey]?.color || "#000" }}>
              {`${displayNameHigher}: ${itemHigher.value}`}
            </p>
            <p style={{ margin: 0, color: groupColors[lowerKey]?.color || "#000" }}>
              {`${displayNameLower}: ${itemLower.value}`}
            </p>
            <p style={{ margin: 0 }}>{formatDate(label)}</p>
          </div>
        );
      }
    } else {
      const entry = payload[0];
      const key = entry.dataKey.split('.')[1];
      const displayName = nameMapping ? (nameMapping[key] || key) : key;
      let extraInfo = '';
      if (
        entry.payload &&
        entry.payload.winner !== undefined &&
        entry.payload.winner_score !== undefined &&
        entry.payload.loser_score !== undefined &&
        entry.payload.final_period !== undefined
      ) {
        extraInfo = `${entry.payload.winner} ${entry.payload.winner_score} — ${entry.payload.loser_score} ${entry.payload.loser}`;
        if (entry.payload.final_period === 'OT' || entry.payload.final_period === 'SO') {
          extraInfo += ` (${entry.payload.final_period})`;
        }
      }
      return (
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,0.9)',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          <p style={{ margin: 0, color: groupColors[key]?.color || "#000" }}>
            {`${displayName}: ${entry.value}`}
          </p>
          {extraInfo && <p style={{ margin: 0 }}>{extraInfo}</p>}
          <p style={{ margin: 0 }}>{formatDate(label)}</p>
        </div>
      );
    }
  }
  return null;
};

const SnakeChart = (props) => {
  const [activeLine, setActiveLine] = useState(null);
  const {
    data,
    groupColors,
    yAxisLabel,
    playerNames,
    teamNames,
    season,
    playerInfo,
    // For team grouping: if true, render the logo at every point; if false, render only at the final point.
    logo_points = false,
  } = props;
  const nameMapping = playerNames || teamNames || {};

  if (!data || data.length === 0) {
    return <div>Loading chart...</div>;
  }

  // Determine grouping: if data[0] has 'players', group by player; if 'teams', group by team.
  const isTeamGrouping = data[0].hasOwnProperty('teams');
  const statDataKey = isTeamGrouping ? 'teams' : 'players';

  // Compute union of keys across all data points.
  const statKeys = Array.from(new Set(data.flatMap(d => Object.keys(d[statDataKey]))));
  const isTwoLines = statKeys.length === 2;

  // Compute final values from the last data point.
  const finalData = data[data.length - 1];
  const finalValues = statKeys.reduce((acc, key) => {
    acc[key] = finalData[statDataKey][key];
    return acc;
  }, {});
  let higherKey, lowerKey;
  if (isTwoLines) {
    const [key1, key2] = statKeys;
    if (finalValues[key1] >= finalValues[key2]) {
      higherKey = key1;
      lowerKey = key2;
    } else {
      higherKey = key2;
      lowerKey = key1;
    }
  }

  // Define a base size for mug/logo offsets (for offset calculations only).
  const baseMugSize = 60;
  const finalHorizontalOffset = -baseMugSize;

  const renderCustomDot = (dotProps) => {
    const { cx, cy, index, dataLength, dataKey, payload } = dotProps;
    // Default dot: a small circle.
    // const dotColor = groupColors && groupColors[index] ? groupColors[index].color : "#8884d8";
    const defaultDot = <circle cx={cx} cy={cy} r={4} fill="none" stroke="none" />;

    // Scenario 1: Team grouping with logo_points true.
    // Render the team logo at every data point using fixed offsets.
    if (isTeamGrouping && logo_points) {
      if (payload && payload.winner) {
        const logoUrl = `https://assets.nhle.com/logos/nhl/svg/${payload.winner}_light.svg`;
        return (
          <g>
            {defaultDot}
            <image
              href={logoUrl}
              x={cx - 25}
              y={cy - 25}
              width={50}
              height={50}
            />
          </g>
        );
      }
      return defaultDot;
    }

    // Scenario 2: All other cases (player grouping, or team grouping with logo_points false).
    // Only render the logo/mug at the final data point.
    if (index !== dataLength - 1) return defaultDot;

    // For the final point, extract the key.
    const parts = dataKey.split('.');
    const key = parts[1];

    // Compute vertical offset:
    let verticalOffset = -baseMugSize;

    // For team grouping, the logo URL is built from the team abbreviation (which is our key).
    // For players, we need playerInfo.
    const logoUrl = isTeamGrouping
      ? `https://assets.nhle.com/logos/nhl/svg/${key}_light.svg`
      : `https://assets.nhle.com/mugs/nhl/${season}/${playerInfo[key].team_abb}/${key}.png`;

    return (
      <g>
        {defaultDot}
        <image
          className={styles.mug}
          href={logoUrl}
          x={cx + finalHorizontalOffset}
          y={cy + verticalOffset}
        />
      </g>
    );
  };

  // Compute tick values for the XAxis.
  const tickStep = Math.max(1, Math.ceil(data.length / 10));
  let ticks = data.filter((_, index) => index % tickStep === 0).map((d) => d.date);
  if (ticks[ticks.length - 1] !== data[data.length - 1].date) {
    ticks.push(data[data.length - 1].date);
  }

  // For a single line, render an Area for shading.
  const areaShading = statKeys.length === 1 ? (
    <Area
      type="monotone"
      dataKey={`${statDataKey}.${statKeys[0]}`}
      baseValue={0}
      stroke="none"
      fill={groupColors[statKeys[0]].color}
      fillOpacity={0.3}
      connectNulls
    />
  ) : null;

  return (
    <ResponsiveContainer width="100%" height={800}>
      <ComposedChart data={data} margin={{ top: 80, right: 20, bottom: 20, left: 50 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine y={0} stroke="#595959" strokeWidth={1} />
        <XAxis
          dataKey="date"
          tickFormatter={formatDate}
          ticks={ticks}
          label={{ value: 'Date', position: 'insideBottom', dy: 20, textAnchor: 'middle' }}
        />
        <YAxis label={<CenteredYAxisLabel value={yAxisLabel} />} />
        <Tooltip
          content={
            <CustomTooltip
              nameMapping={nameMapping}
              yAxisLabel={yAxisLabel}
              isTwoLines={isTwoLines}
              groupColors={groupColors}
              higherKey={higherKey}
              lowerKey={lowerKey}
            />
          }
        />
        {areaShading}
        {statKeys.map((key) => {
          const isActive = activeLine === key;
          return (
            <Line
              key={key}
              type="monotone"
              dataKey={`${statDataKey}.${key}`}
              name={nameMapping[key] || key}
              stroke={isActive ? groupColors[key].highlight : groupColors[key].color}
              strokeWidth={isActive ? 8 : 4}
              dot={(dotProps) =>
                renderCustomDot({
                  ...dotProps,
                  dataLength: data.length,
                  dataKey: `${statDataKey}.${key}`,
                })
              }
              activeDot={false}
              connectNulls
              onMouseEnter={() => setActiveLine(key)}
              onMouseLeave={() => setActiveLine(null)}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SnakeChart;
