import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SnakeChart from './SnakeChart';
import styles from '../styles/CompareSnakes.module.css';
import SnakeChartInputFilters from "./SnakeChartInputFilters";
//
// const commonStatOptions = [
//   { value: 'expected_goals_scored', text: 'Expected Goals Scored' },
//   { value: 'cumulative_expected_goals_scored', text: 'Cumulative Expected Goals Scored' },
//   { value: 'cumulative_goals_above_expected', text: 'Cumulative Goals Above Expected' },
// ];
// const teamStatOptions = [
//   { value: 'net_expected_goals', text: 'Net Expected Goals' },
//   { value: 'cumulative_net_expected_goals', text: 'Cumulative Net Expected Goals' },
//   { value: 'expected_goals_scored', text: 'Expected Goals Scored' },
//   { value: 'cumulative_expected_goals_scored', text: 'Cumulative Expected Goals Scored' },
//   { value: 'cumulative_goals_above_expected', text: 'Cumulative Goals Above Expected' },
//   { value: 'goals_above_expected', text: 'Goals Above Expected' },
//   { value: 'goals_saved_above_expected', text: 'Goals Saved Above Expected' },
//   { value: 'cumulative_goals_saved_above_expected', text: 'Cumulative Goals Saved Above Expected' },
//   // Add more team-specific stats here
// ];
//
// const playerStatOptions = [
//   { value: 'game_rating', text: 'Game Rating' },
//   { value: 'cumulative_game_rating', text: 'Cumulative Game Rating' },
//   { value: 'expected_goals_scored', text: 'Expected Goals Scored' },
//   { value: 'cumulative_expected_goals_scored', text: 'Cumulative Expected Goals Scored' },
//   { value: 'cumulative_goals_above_expected', text: 'Cumulative Goals Above Expected' },
//   { value: 'net_expected_goals_on_ice_even', text: 'Net EV xG On Ice For' },
//   { value: 'cumulative_net_expected_goals_on_ice_even', text: 'Cumulative Net EV xG On Ice For'},
//   // Add more player-specific stats here
// ];
//
// const goalieStatOptions = [
//   { value: 'game_rating', text: 'Game Rating' },
//   { value: 'cumulative_game_rating', text: 'Cumulative Game Rating' },
//   { value: 'goals_saved_above_expected', text: 'Goals Saved Above Expected' },
//   { value: 'cumulative_goals_saved_above_expected', text: 'Cumulative Goals Saved Above Expected' },
//   // Add more goalie-specific stats here
// ];
const commonStatOptions = [
  { value: 'expected_goals_scored', label:'Expected Goals Scored' },
  { value: 'cumulative_expected_goals_scored', label:'Cumulative Expected Goals Scored' },
  { value: 'cumulative_goals_above_expected', label:'Cumulative Goals Above Expected' },
];
const teamStatOptions = [
  { value: 'net_expected_goals', label:'Net Expected Goals' },
  { value: 'cumulative_net_expected_goals', label:'Cumulative Net Expected Goals' },
  { value: 'expected_goals_scored', label:'Expected Goals Scored' },
  { value: 'cumulative_expected_goals_scored', label:'Cumulative Expected Goals Scored' },
  { value: 'cumulative_goals_above_expected', label:'Cumulative Goals Above Expected' },
  { value: 'goals_above_expected', label:'Goals Above Expected' },
  { value: 'goals_saved_above_expected', label:'Goals Saved Above Expected' },
  { value: 'cumulative_goals_saved_above_expected', label:'Cumulative Goals Saved Above Expected' },
  // Add more team-specific stats here
];

const playerStatOptions = [
  { value: 'game_rating', label:'Game Rating' },
  { value: 'cumulative_game_rating', label:'Cumulative Game Rating' },
  { value: 'expected_goals_scored', label:'Expected Goals Scored' },
  { value: 'cumulative_expected_goals_scored', label:'Cumulative Expected Goals Scored' },
  { value: 'cumulative_goals_above_expected', label:'Cumulative Goals Above Expected' },
  { value: 'net_expected_goals_on_ice_even', label:'Net EV xG On Ice For' },
  { value: 'cumulative_net_expected_goals_on_ice_even', label:'Cumulative Net EV xG On Ice For'},
  // Add more player-specific stats here
];

const goalieStatOptions = [
  { value: 'game_rating', label:'Game Rating' },
  { value: 'cumulative_game_rating', label:'Cumulative Game Rating' },
  { value: 'goals_saved_above_expected', label:'Goals Saved Above Expected' },
  { value: 'cumulative_goals_saved_above_expected', label:'Cumulative Goals Saved Above Expected' },
  // Add more goalie-specific stats here
];

const allStatOptions = [
  ...commonStatOptions,
  ...teamStatOptions,
  ...playerStatOptions,
  ...goalieStatOptions,
];

// Helper function to get the display text for a stat value.
const getStatText = (statValue) => {
  const found = allStatOptions.find(option => option.value === statValue);
  return found ? found.label : statValue.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
};

function SnakeChartContainer({ teamAbb, selectedSeason, player }) {
  const playerId = player?.id;  //
  const isGoalie = player?.position === 'G';
  const hasTeam = !!teamAbb;
  const hasPlayer = !!playerId;

  let statOptions;
  if (hasTeam && !hasPlayer) {
    statOptions = teamStatOptions;
  } else if (!hasTeam && hasPlayer) {
    statOptions = isGoalie ? goalieStatOptions : playerStatOptions;
  } else if (hasTeam && hasPlayer) {
    statOptions = isGoalie
      ? [...teamStatOptions, ...goalieStatOptions]
      : [...teamStatOptions, ...playerStatOptions];
  }

  const [selectedStat, setSelectedStat] = useState(statOptions[0]?.value || '');
  const [selectedNumTimePeriods, setSelectedNumTimePeriods] = useState(10);
  const [selectedGroupBy, setSelectedGroupBy] = useState('game');
  const [loading, setLoading] = useState(true);
  const [snakeData, setSnakeData] = useState(null);
  const [chartTitle, setChartTitle] = useState('');

  const displayLabel = () => {
    if (player && player.first_name && player.last_name) {
      return `${player.first_name} ${player.last_name}`;
    } else if (teamAbb) {
      return teamAbb;
    } else {
      return '';
    }
  };

  const maxGames = selectedNumTimePeriods;

  async function fetchSnakeData() {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        season: selectedSeason,
        stat: selectedStat,
        group_by: playerId ? 'player' : 'team',
        cumulative: selectedStat.includes('cumulative') ? 'true' : 'false',
        x_axis: selectedGroupBy,
        max_time_periods: maxGames,
      });

      if (teamAbb) params.append('team_abbs', teamAbb);
      if (playerId) params.append('player_ids', playerId);

      const apiUrl = `/api/snake-data/?${params.toString()}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      setSnakeData(data);
    } catch (error) {
      console.error('Error fetching snake data:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const title = `${displayLabel()} ${getStatText(selectedStat)}: Last ${selectedNumTimePeriods} ${selectedGroupBy === 'week' ? 'Weeks' : 'Games'}`;
    setChartTitle(title);
    fetchSnakeData();
  }, []);

  const handleReloadChart = async () => {
    const title = `${displayLabel()} ${getStatText(selectedStat)}: Last ${selectedNumTimePeriods} ${selectedGroupBy === 'week' ? 'Weeks' : 'Games'}`;
    setChartTitle(title);
    await fetchSnakeData();
  };

  const toTitleCase = (str) =>
    str.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return (
    <div className={styles.componentContainer}>
      <div className={styles.additionalFilters}>
        <SnakeChartInputFilters
          statOptions={statOptions}
          selectedStat={selectedStat}
          onChangeStat={setSelectedStat}
          selectedNum={selectedNumTimePeriods}
          onChangeNum={setSelectedNumTimePeriods}
          selectedGroupBy={selectedGroupBy}
          onChangeGroupBy={setSelectedGroupBy}
        />
      </div>

      {/* Button to reload the chart */}
      <button
        className={styles.generateButton}
        onClick={handleReloadChart}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Reload Chart'}
      </button>

      {chartTitle && <h3 className={styles.chartTitle}>{chartTitle}</h3>}

      {/*{!loading && (*/} {/*  <h1 className={styles.snakeChartTitle}>*/} {/*    /!*{displayLabel()} {getStatText(selectedStat)} — {`Last ${maxGames} GP`}*!/*/} {/*    {displayLabel()} {getStatText(selectedStat)}*/} {/*  </h1>*/} {/*)}*/}

      {loading ? (
        <div className={styles.spinnerContainer}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        snakeData?.data && (
          <SnakeChart
            data={snakeData.data}
            playerNames={snakeData.player_names || snakeData.team_names}
            groupColors={snakeData.group_colors}
            yAxisLabel={snakeData.y_axis_label}
            season={selectedSeason}
            playerInfo={snakeData.mug_info}
          />
        )
      )}
    </div>
  );
}

SnakeChartContainer.propTypes = {
  teamAbb: PropTypes.string,
  selectedSeason: PropTypes.string.isRequired,
  playerId: PropTypes.number,
  player: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default SnakeChartContainer;
