import React from 'react';
import Select from 'react-select';
import styles from '../styles/CompareSnakes.module.css';

const SnakeChartInputFilters = ({
  statOptions,
  selectedStat,
  onChangeStat,
  selectedNum,
  onChangeNum,
  selectedGroupBy,
  onChangeGroupBy,
}) => {
  return (
    <div>
      {/* Divider to visually separate from required selectors */}
      <hr className={styles.sectionDivider} />

      <div className={styles.additionalFilters}>
        <div className={styles.filterGroup}>
          <label className={styles.filterLabel}>Select Stat</label>
          <Select
            className={styles.statSelectorDropdown}
            options={statOptions}
            value={statOptions.find(opt => opt.value === selectedStat)}
            onChange={(option) => onChangeStat(option.value)}
            placeholder="Select Stat"
          />
        </div>
        <div className={styles.filterGroup}>
          <label className={styles.filterLabel}>Last</label>
          <div className={styles.numberInputWrapper}>
            <input
              type="number"
              min="1"
              step="1"
              value={selectedNum || ''}
              onChange={(e) => onChangeNum(Number(e.target.value))}
              placeholder="#"
              className={styles.numberInput}
            />
          </div>
        </div>
        <div className={styles.filterGroup}>
          <label className={styles.filterLabel}>Group By</label>
          <Select
            className={styles.groupByDropdown}
            options={[
              { value: 'game', label: 'Games' },
              { value: 'week', label: 'Weeks' },
            ]}
            value={
              // Ensure we have an object; if none is selected, null
              selectedGroupBy
                ? { value: selectedGroupBy, label: selectedGroupBy === 'week' ? 'Weeks' : 'Games' }
                : null
            }
            onChange={(option) => onChangeGroupBy(option.value)}
            placeholder="Select Group By"
          />
        </div>
      </div>
    </div>
  );
};

export default SnakeChartInputFilters;
