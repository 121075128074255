import React, { useState, useEffect, useCallback } from 'react';
import SeasonDropdown from '../components/SeasonDropdown';
import Select from 'react-select';
import SnakeChart from '../components/SnakeChart';
import styles from '../styles/CompareSnakes.module.css';
import SnakeChartInputFilters from "./SnakeChartInputFilters";

const statOptionsTeam = [
  { value: 'net_expected_goals', label: 'Net Expected Goals' },
  { value: 'cumulative_net_expected_goals', label: 'Cumulative Net Expected Goals' },
  { value: 'expected_goals_scored', label: 'Expected Goals Scored' },
  { value: 'cumulative_expected_goals_scored', label: 'Cumulative Expected Goals Scored' },
  { value: 'cumulative_goals_above_expected', label: 'Cumulative Goals Above Expected' },
  { value: 'goals_above_expected', label: 'Goals Above Expected' },
  { value: 'goals_saved_above_expected', label: 'Goals Saved Above Expected' },
  { value: 'cumulative_goals_saved_above_expected', label: 'Cumulative Goals Saved Above Expected' },
];

export default function CompareTeamSnakes({ season }) {
  // For team comparisons, the filter bar only needs the team filter.
  // const [teamFilter, setTeamFilter] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [chartTitle, setChartTitle] = useState('');

  // List of teams fetched from API.
  const [filteredTeams, setFilteredTeams] = useState([]);

  // Selected teams for the two dropdowns.
  const [selectedTeams, setSelectedTeams] = useState([null, null]);

  // Additional dropdowns.
  const [selectedStat, setSelectedStat] = useState(statOptionsTeam[0].value);
  // const [selectedNumGames, setSelectedNumGames] = useState(null);
  const [selectedNumTimePeriods, setSelectedNumTimePeriods] = useState(null);
  const [selectedGroupBy, setSelectedGroupBy] = useState(null);

  // Chart state.
  const [loadingChart, setLoadingChart] = useState(false);
  const [snakeData, setSnakeData] = useState(null);

  // --- Fetch team options on mount ---
  useEffect(() => {
    async function getTeamOptions() {
      try {
        const teamResp = await fetch("/api/teams/");
        const teamData = await teamResp.json();
        const mappedTeams = teamData.map((t) => ({
          value: t.abb,
          label: t.location + ' ' + t.name,
        }));
        setTeamOptions(mappedTeams);
        setFilteredTeams(mappedTeams);
      } catch (err) {
        console.error("Error fetching team options:", err);
      }
    }
    getTeamOptions();
  }, []);

  // --- For team comparisons, we use the teamOptions as the filteredTeams list.
  // (If you need further filtering, you could add that here.)

  // Options for team dropdowns (exclude team already selected in the other dropdown).
  const optionsForDropdown = (dropdownIndex) => {
    return filteredTeams.filter(team => {
      const otherIndex = dropdownIndex === 0 ? 1 : 0;
      return selectedTeams[otherIndex] !== team.value;
    });
  };

  const handleTeamSelect = (index, selectedOption) => {
    setSelectedTeams(prev => {
      const newSelection = [...prev];
      newSelection[index] = selectedOption ? selectedOption.value : null;
      return newSelection;
    });
  };

  // --- Generate Chart ---
  const canGenerate =
    selectedTeams.every(t => !!t) &&
    selectedStat &&
    // selectedNumGames &&
    selectedNumTimePeriods &&
    selectedGroupBy;

  const handleGenerateChart = async () => {
    if (!canGenerate) {
      console.warn("Please complete all selections.");
      return;
    }
    setLoadingChart(true);
    setSnakeData(null);

    const selectedStatLabel = statOptionsTeam.find(opt => opt.value === selectedStat)?.label || selectedStat;

    // const selectedStatLabel = (compareType === 'goalies' ? statOptionsGoalies : statOptionsPlayers)
    //   .find(opt => opt.value === selectedStat)?.label || selectedStat;

    const title = `${selectedStatLabel}: Last ${selectedNumTimePeriods} ${selectedGroupBy === 'week' ? 'Weeks' : 'Games'}`;
    setChartTitle(title);

    // Build API request parameters.
    const params = new URLSearchParams({
      season,
      stat: selectedStat,
      group_by: 'team',
      cumulative: selectedStat.includes('cumulative') ? 'true' : 'false',
      x_axis: selectedGroupBy,
      // max_games: selectedNumGames.toString(),
      max_time_periods: selectedNumTimePeriods.toString()
    });
    // For team comparisons, pass selected team IDs (or abb) like player_ids.
    selectedTeams.forEach(team => params.append('team_abbs', team));

    try {
      const response = await fetch(`/api/snake-data/?${params.toString()}`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setSnakeData(data);
    } catch (err) {
      console.error("Error fetching chart data:", err);
    } finally {
      setLoadingChart(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.sectionTitle}>Compare Team Stats</h2>

      {/* Two Team Dropdowns */}
      <div className={styles.playerSelectors}>
        <div className={styles.playerSelector}>
          <label>Team 1</label>
          <Select
            className={styles.playerDropdown}
            options={optionsForDropdown(0)}
            onChange={(option) => handleTeamSelect(0, option)}
            value={optionsForDropdown(0).find(opt => opt.value === selectedTeams[0]) || null}
            placeholder="Select Team 1"
          />
        </div>
        <div className={styles.playerSelector}>
          <label>Team 2</label>
          <Select
            className={styles.playerDropdown}
            options={optionsForDropdown(1)}
            onChange={(option) => handleTeamSelect(1, option)}
            value={optionsForDropdown(1).find(opt => opt.value === selectedTeams[1]) || null}
            placeholder="Select Team 2"
          />
        </div>
      </div>
      <SnakeChartInputFilters
        statOptions={statOptionsTeam}
        selectedStat={selectedStat}
        onChangeStat={setSelectedStat}
        selectedNum={selectedNumTimePeriods}
        onChangeNum={setSelectedNumTimePeriods}
        selectedGroupBy={selectedGroupBy}
        onChangeGroupBy={setSelectedGroupBy}
      />

      {/* Additional Dropdowns */}
      {/*<div className={styles.additionalFilters}>*/}
      {/*  <div className={styles.filterGroup}>*/}
      {/*    <label className={styles.filterLabel}>Select Stat</label>*/}
      {/*    <Select*/}
      {/*      className={styles.statSelector}*/}
      {/*      options={statOptionsTeam}*/}
      {/*      value={statOptionsTeam.find(opt => opt.value === selectedStat)}*/}
      {/*      onChange={(option) => setSelectedStat(option.value)}*/}
      {/*      placeholder="Select Stat"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className={styles.filterGroup}>*/}
      {/*    <label className={styles.filterLabel}>Number of Games</label>*/}
      {/*    <Select*/}
      {/*      options={numGamesOptions}*/}
      {/*      // onChange={(option) => setSelectedNumGames(option.value)}*/}
      {/*      // value={numGamesOptions.find(opt => opt.value === selectedNumGames) || null}*/}
      {/*      onChange={(option) => setSelectedNumTimePeriods(option.value)}*/}
      {/*      value={numGamesOptions.find(opt => opt.value === selectedNumTimePeriods) || null}*/}
      {/*      placeholder="Select # of Games"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className={styles.filterGroup}>*/}
      {/*    <label className={styles.filterLabel}>Group By</label>*/}
      {/*    <Select*/}
      {/*      options={groupByOptions}*/}
      {/*      onChange={(option) => setSelectedGroupBy(option.value)}*/}
      {/*      value={groupByOptions.find(opt => opt.value === selectedGroupBy) || null}*/}
      {/*      placeholder="Select Group By"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* Generate Chart Button */}
      <button
        className={styles.generateButton}
        onClick={handleGenerateChart}
        disabled={!canGenerate || loadingChart}
      >
        {loadingChart ? 'Loading...' : 'Generate Chart'}
      </button>

      {chartTitle && <h3 className={styles.chartTitle}>{chartTitle}</h3>}

      {/* Render the SnakeChart if data is available */}
      {snakeData?.data && (
        <SnakeChart
          data={snakeData.data}
          teamNames={snakeData.team_names}
          groupColors={snakeData.group_colors}
          yAxisLabel={snakeData.y_axis_label}
          season={season}
          // For teams, we expect mug_info if using final-point logos.
          playerInfo={snakeData.mug_info}
          // Note: logo_points for teams can be controlled in SnakeChart via a prop.
          logo_points={false}  // Default: final-point offset behavior.
        />
      )}
    </div>
  );
}
