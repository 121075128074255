import { Link } from 'react-router-dom'
import styles from '../styles/NavBar.module.css'
import React, {useEffect, useState} from "react";

// const NavBar = () => {
//     return (
//         <nav className={styles.navBar}>
//             <Link to="/">
//                 {/*<h1 className={styles.siteHeading}>Muffin Hockey</h1>*/}
//                 <img className={styles.muffinLogo} src={`${process.env.PUBLIC_URL}/muffinhockey.png`} alt={'muffin hockey logo'}/>
//             </Link>
//             {/*<Link to="/players">*/}
//             {/*     <button className={styles.randomButton}>Random Player</button>*/}
//             {/*</Link>*/}
//         </nav>
//     );
// }

// function RenderTeamList(teams) {
//     return teams.map(team => (
//         // <Link className={styles.teamRows} to={`/teams/${team.abb}`} >
//         <Link key={team.abb} className={styles.teamRows} to={`/teams/${team.abb}/`}>
//             <li className="list-group-item list-group-item-action"
//                 key={team.id}
//             >
//                 <span className="me-10">
//                     <img className={styles.teamLogo}
//                          src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
//                          alt={`${team.abb} logo`}/>
//                 </span>
//                 <span className={styles.teamName}>
//                     {'    ' + team.location + ' ' + team.name}
//                 </span>
//             </li>
//         </Link>
//     ));
// }
//
// const NavBar = () => {
//     // State to handle dropdown visibility
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [teams, setTeams] = useState([]);
//     const [loading, setLoading] = useState(true);
//
//     useEffect(() => {
//         getData().catch(err => {
//             console.log(err);
//         });
//     }, []);
//
//     // Toggle dropdown visibility
//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//     };
//
//     const getData = async () => {
//         setLoading(true);
//         const teamResp = await fetch("https://muffinhockey.com/api/teams/");
//         const teamList = await teamResp.json();
//         setTeams(teamList);
//         setLoading(false);
//     };
//
//     if (loading) return (
//         <div className="d-flex justify-content-center">
//             <div className="spinner-border" role="status">
//                 <span className="visually-hidden">Loading...</span>
//             </div>
//         </div>
//     );
//
//     return (
//         <nav className={styles.navBar}>
//             {/* Top Row: Logo */}
//             <div className={styles.logoContainer}>
//                 <Link to="/">
//                     <img className={styles.muffinLogo} src={`${process.env.PUBLIC_URL}/muffinhockey.png`} alt="muffin hockey logo" />
//                 </Link>
//             </div>
//
//             {/* Bottom Row: Buttons */}
//             <div className={styles.navButtonsContainer}>
//                 <Link to="/">
//                     <button className={styles.navButton}>Home</button>
//                 </Link>
//
//                 <div className={styles.dropdownContainer}>
//                     <button className={styles.navButton} onClick={toggleDropdown}>
//                         Teams
//                     </button>
//
//                     {dropdownVisible && (
//                         <div className={styles.overlayDropdown}>
//                             {RenderTeamList(teams)}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </nav>
//     );
// };
//
// export { NavBar };


// RenderTeamList Component
// const RenderTeamList = ({ teams, setDropdownVisible }) => {
//     return (
//         <ul className={styles.dropdownMenu}>
//             {teams.map(team => (
//                 <li key={team.abb} className={`list-group-item list-group-item-action ${styles.teamRows}`}>
//                     <Link
//                         className={styles.teamLink}
//                         to={`/teams/${team.abb}/`}
//                         onClick={() => setDropdownVisible(false)}
//                     >
//                         <span className="me-10">
//                             <img
//                                 className={styles.teamLogo}
//                                 src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
//                                 alt={`${team.abb} logo`}
//                             />
//                         </span>
//                         <span>{`${team.location} ${team.name}`}</span>
//                     </Link>
//                 </li>
//             ))}
//         </ul>
//     );
// };
const RenderTeamList = ({ teams, setDropdownVisible }) => {
    return (
        <ul className={styles.dropdownMenu}>
            {teams.map(team => (
                <li key={team.abb} className={`list-group-item list-group-item-action ${styles.teamRows}`}>
                    <Link
                        className={styles.teamLink}
                        to={`/teams/${team.abb}/`}
                        onClick={() => setDropdownVisible(false)}
                    >
                        <span className="me-10">
                            <img
                                className={styles.teamLogo}
                                src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
                                alt={`${team.abb} logo`}
                            />
                        </span>
                        <span>{`${team.location} ${team.name}`}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
};


const NavBar = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const teamResp = await fetch('/api/teams/');
                const teamList = await teamResp.json();
                setTeams(teamList);
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
            setLoading(false);
        };

        getData();
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <nav className={styles.navBar}>
            <div className={styles.logoContainer}>
                <Link to="/">
                    <img
                        className={styles.muffinLogo}
                        src={`${process.env.PUBLIC_URL}/muffinhockey.png`}
                        alt="muffin hockey logo"
                    />
                </Link>
            </div>

            <div className={styles.navButtonsContainer}>
                <Link to="/">
                    <button className={styles.navButton}>Home</button>
                </Link>

                <Link to={`/leaders`}>
                    <button className={styles.navButton}>League Leaders</button>
                </Link>

                <Link to={`/player_insights`}>
                    <button className={styles.navButton}>Player Ratings</button>
                </Link>

                <Link to={`/shared_ice_comparison`}>
                    <button className={styles.navButton}>Head to Head</button>
                </Link>

                <Link to={`/chart_comparison`}>
                    <button className={styles.navButton}>Compare Charts</button>
                </Link>

                <Link to={`/teams/NHL/games`}>
                    <button className={styles.navButton}>Recent Games</button>
                </Link>

                {/* Split button: Main button + Dropdown arrow */}
                <div className={styles.dropdownContainer}>
                    {/*<Link to="/teams">*/}
                    {/*    <button className={styles.navButton}>Teams</button>*/}
                    {/*</Link>*/}
                    <button className={styles.dropdownArrowButton} onClick={toggleDropdown}>
                        Teams
                    </button>

                    {dropdownVisible && (
                        <div className={styles.overlayDropdown}>
                            <RenderTeamList teams={teams} setDropdownVisible={setDropdownVisible} />
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export { NavBar };


// const NavBar = () => {
//     // State to handle dropdown visibility
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [teams, setTeams] = useState([])
//     const [loading, setLoading] = useState(true);
//
//     useEffect(() => {
//         getData()
//             .catch(err => {
//                 console.log(err);
//       });
//     }, [])
//
//     // Toggle dropdown visibility
//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//     };
//     const getData = async () => {
//         setLoading(true)
//         let apiUrl =
//         "https://muffinhockey.com/api/teams/"
//         const teamResp = await fetch(apiUrl)
//         const teamList = await teamResp.json()
//         setTeams(teamList)
//         setLoading(false)
//     }
//
//     if(loading) return (
//         <div className="d-flex justify-content-center">
//             <div className="spinner-border" role="status">
//                 <span className="visually-hidden">Loading...</span>
//             </div>
//         </div>
//     );
//
//     return (
//         <nav className={styles.navBar}>
//             <Link to="/">
//                 <img className={styles.muffinLogo} src={`${process.env.PUBLIC_URL}/muffinhockey.png`} alt="muffin hockey logo" />
//             </Link>
//
//             <div className={styles.navButtonsContainer}>
//                 {/* Home Button */}
//                 <Link to="/">
//                     <button className={styles.navButton}>Home</button>
//                 </Link>
//
//                 {/* Teams Button with Dropdown */}
//                 <div className={styles.dropdownContainer}>
//                     <button className={styles.navButton} onClick={toggleDropdown}>
//                         Teams
//                     </button>
//
//                     {dropdownVisible && (
//                         RenderTeamList(teams)
//                     )}
//                 </div>
//             </div>
//         </nav>
//     );
// };
// export { NavBar };