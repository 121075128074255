import React, {useEffect, useState} from "react";
import styles from "../styles/LeagueLeaders.module.css";
import SeasonDropdown from "../components/SeasonDropdown";
import PlayerRatingsTable from "../components/PlayerRatingsTable";
import LZString from "lz-string";

export function PlayerInsights() {
    const [bestOveralls, setBestOveralls] = useState({});
    const [bestLeftWingers, setBestLeftWingers] = useState({});
    const [bestCenters, setBestCenters] = useState({});
    const [bestRightWingers, setBestRightWingers] = useState({});
    const [bestDefensemen, setBestDefensemen] = useState({});
    const [bestGoalies, setBestGoalies] = useState({});
    const [loading, setLoading] = useState(true);
    const selectedSeason = 20242025

    useEffect(() => {
        setLoading(true);
        getData()
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, []); // Re-fetch data when teamAbb or selectedSeason change

    const getData = async () => {
        setLoading(true);

        const cacheExpiryTime = 60 * 60 * 1000; // Cache expiration time in milliseconds (1 hour)
        const cachedData = localStorage.getItem(`cachedData-insights`);
        const cacheTimestamp = localStorage.getItem(`cachedTimestamp-insights`);
        const currentTime = new Date().getTime();

        if (cachedData && cacheTimestamp && (currentTime - cacheTimestamp < cacheExpiryTime)) {
            const parsedData = JSON.parse(cachedData);
            const { bestOveralls, bestLeftWingers, bestCenters, bestRightWingers, bestDefensemen, bestGoalies } = parsedData;
            setBestOveralls(bestOveralls);
            setBestLeftWingers(bestLeftWingers);
            setBestCenters(bestCenters);
            setBestRightWingers(bestRightWingers);
            setBestDefensemen(bestDefensemen);
            setBestGoalies(bestGoalies);
            setLoading(false);
        } else {
            try {
                const [bestOverallsData, bestLwData, bestCData, bestRwData, bestDData, bestGData] = await Promise.all([
                    fetch(`/api/players/insights/overall`).then(res => res.json()),
                    fetch(`/api/players/insights/lw`).then(res => res.json()),
                    fetch(`/api/players/insights/c`).then(res => res.json()),
                    fetch(`/api/players/insights/rw`).then(res => res.json()),
                    fetch(`/api/players/insights/d`).then(res => res.json()),
                    fetch(`/api/players/insights/g`).then(res => res.json())
                ]);

                setBestOveralls(bestOverallsData);
                setBestLeftWingers(bestLwData);
                setBestCenters(bestCData);
                setBestRightWingers(bestRwData);
                setBestDefensemen(bestDData);
                setBestGoalies(bestGData)

                const dataToCache = {
                    bestOveralls: bestOverallsData,
                    bestLeftWingers: bestLwData,
                    bestCenters: bestCData,
                    bestRightWingers: bestRwData,
                    bestDefensemen: bestDData,
                    bestGoalies: bestGData,
                };

                localStorage.setItem(`cachedData-insights`, JSON.stringify(dataToCache));
                localStorage.setItem(`cachedTimestamp-insights`, new Date().getTime());
            } catch (err) {
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        }
    };

     if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.pageContainer}>
            <h1 className={styles.pageHeading}>Player Ratings</h1>
            <h2 className={styles.statCategory}>Top 10 Overall Ratings</h2>
            {bestOveralls && (
                <PlayerRatingsTable playerRatings={bestOveralls} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Top 10 LW</h2>
            {bestLeftWingers && (
                <PlayerRatingsTable playerRatings={bestLeftWingers} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Top 10 C</h2>
            {bestCenters && (
                <PlayerRatingsTable playerRatings={bestCenters} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Top 10 RW</h2>
            {bestRightWingers && (
                <PlayerRatingsTable playerRatings={bestRightWingers} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Top 10 Defensemen</h2>
            {bestDefensemen && (
                <PlayerRatingsTable playerRatings={bestDefensemen} season={selectedSeason}/>
            )}

            <h2 className={styles.statCategory}>Top 10 Goalies</h2>
            {bestGoalies && (
                <PlayerRatingsTable playerRatings={bestGoalies} season={selectedSeason}/>
            )}
        </div>
    );
}