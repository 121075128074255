import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from './pages/Home';
import TeamSelectionPage from "./pages/TeamSelectionPage";
import TeamPlayersPage from "./pages/TeamPlayersPage";
import TeamHomePage from "./pages/TeamHomePage"
import PlayerPage from "./pages/PlayerPage";
import React from "react";
import { NavBar} from './components/NavBar'
import styles from './App.module.css'
import TeamGamesPage from "./pages/TeamGamesPage";
import GamePage from "./pages/GamePage";
import {MuffinFooter} from "./components/MuffinFooter";
import {LeagueLeaders} from "./pages/LeagueLeaders";
import {PlayerInsights} from "./pages/PlayerInsights";
import CompareSharedIce from "./pages/CompareSharedIce";
import CompareSnakesPage from "./pages/CompareSnakesPage";

// export default const App = () => {
export default function App() {
    return(
        <BrowserRouter>
            <NavBar />
            <div className={styles.contentContainer}>
                <Routes>
                    <Route path="/" exact element={<HomePage />}/>
                    <Route path="/teams" element={<TeamSelectionPage />}/>
                    <Route path="/teams/:teamAbb" element={<TeamHomePage />}/>
                    <Route path="/teams/:teamAbb/roster" element={<TeamPlayersPage />}/>
                    <Route path="/teams/:teamAbb/games" element={<TeamGamesPage />}/>
                    <Route path="/players/id/:playerId" element={<PlayerPage />}/>
                    <Route path="/games/id/:gameId" element={<GamePage />}/>
                    <Route path="/leaders" element={<LeagueLeaders />}/>
                    <Route path="/player_insights" element={<PlayerInsights />}/>
                    <Route path="/shared_ice_comparison" element={<CompareSharedIce />}/>
                    <Route path="/chart_comparison" element={<CompareSnakesPage />}/>
                </Routes>
            </div>
            <MuffinFooter/>
        </BrowserRouter>
    )
};