import React, {useEffect, useState} from "react";
import styles from "../styles/LeagueLeaders.module.css";
import SeasonDropdown from "../components/SeasonDropdown";
import PlayerRatingsTable from "../components/PlayerRatingsTable";


export function LeagueLeaders() {
    const [gameRatings, setRatingsLeaders] = useState({});
    const [pointLeaders, setPointLeaders] = useState({});
    const [goalLeaders, setGoalLeaders] = useState({});
    const [xgLeaders, setXgLeaders] = useState({});
    const [scoredaxLeaders, setScoredaxLeaders] = useState({});
    const [primaryPointsLeaders, setPrimaryPointsLeaders] = useState({});
    const [assistsLeaders, setAssistsLeaders] = useState({});
    const [savedaxLeaders, setSavedaxLeaders] = useState({});
    const [savepercentageLeaders, setSavepercentageLeaders] = useState({});
    const [stolenLeaders, setStolenLeaders] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedSeason, setSelectedSeason] = useState('20242025');  // Default season

    // Handle the selected season
    const handleSeasonChange = (season) => {
        setSelectedSeason(season);
    };

    useEffect(() => {
        setLoading(true);
        getData(selectedSeason)
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, [selectedSeason]); // Re-fetch data when teamAbb or selectedSeason change

    const getData = async () => {
        setLoading(true);

      let apiUrl = `/api/leaders/${selectedSeason}/game_ratings`;
      try {
        const ratingsLeadersResp = await fetch(apiUrl);
        const ratingsLeadersData = await ratingsLeadersResp.json();
        setRatingsLeaders(ratingsLeadersData);
      } catch (err) {
          console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }

      apiUrl = `/api/leaders/${selectedSeason}/points`;
      try {
        const pointLeadersResp = await fetch(apiUrl);
        const pointLeadersData = await pointLeadersResp.json();
        setPointLeaders(pointLeadersData);
      } catch (err) {
          console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/goals`;
      try {
        const goalLeadersResp = await fetch(apiUrl);
        const goalLeadersData = await goalLeadersResp.json();
        setGoalLeaders(goalLeadersData);
      } catch (err) {
          console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/xg`;
      try {
        const xgLeadersResp = await fetch(apiUrl);
        const xgLeadersData = await xgLeadersResp.json();
        setXgLeaders(xgLeadersData);
      } catch (err) {
          console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/scoredax`;
      try {
        const scoredaxLeadersResp = await fetch(apiUrl);
        const scoredaxLeadersData = await scoredaxLeadersResp.json();
        setScoredaxLeaders(scoredaxLeadersData);
      } catch (err) {
          console.error('Error fetching data:', err);
      }

      apiUrl = `api/leaders/${selectedSeason}/primary_points`;
      try {
        const primaryPointsLeadersResp = await fetch(apiUrl);
        const primaryPointsLeadersData = await primaryPointsLeadersResp.json();
        setPrimaryPointsLeaders(primaryPointsLeadersData);
      } catch (err) {
            console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/assists`;
      try {
        const assistsLeadersResp = await fetch(apiUrl);
        const assistsLeadersData = await assistsLeadersResp.json();
        setAssistsLeaders(assistsLeadersData);
      } catch (err) {
            console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/savedax`;
      try {
        const savedaxLeadersResp = await fetch(apiUrl);
        const savedaxLeadersData = await savedaxLeadersResp.json();
        setSavedaxLeaders(savedaxLeadersData);
      } catch (err) {
        console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/save_percentage`;
      try {
        const savepercentageLeadersResp = await fetch(apiUrl);
        const savepercentageLeadersData = await savepercentageLeadersResp.json();
        setSavepercentageLeaders(savepercentageLeadersData);
      } catch (err) {
        console.error('Error fetching data:', err);
      }

      apiUrl = `/api/leaders/${selectedSeason}/stolen_games`;
      try {
        const stolenLeadersResp = await fetch(apiUrl);
        const stolenLeadersData = await stolenLeadersResp.json();
        setStolenLeaders(stolenLeadersData);
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

     if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.pageContainer}>
            <h1 className={styles.pageHeading}>League Leaders</h1>
            <SeasonDropdown selectedSeason={selectedSeason} onSeasonChange={handleSeasonChange}/>
            <h2 className={styles.statCategory}>Average Game Rating</h2>
            {gameRatings && (
                <PlayerRatingsTable playerRatings={gameRatings} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Points</h2>
            {pointLeaders && (
                <PlayerRatingsTable playerRatings={pointLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Primary Points</h2>
            {primaryPointsLeaders && (
                <PlayerRatingsTable playerRatings={primaryPointsLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Goals</h2>
            {goalLeaders && (
                <PlayerRatingsTable playerRatings={goalLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Expected Goals</h2>
            {xgLeaders && (
                <PlayerRatingsTable playerRatings={xgLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Goals Scored Above Expected</h2>
            {scoredaxLeaders && (
                <PlayerRatingsTable playerRatings={scoredaxLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Assists</h2>
            {assistsLeaders && (
                <PlayerRatingsTable playerRatings={assistsLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Goals Saved Above Expected</h2>
            {savedaxLeaders && (
                <PlayerRatingsTable playerRatings={savedaxLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Save Percentage</h2>
            {savepercentageLeaders && (
                <PlayerRatingsTable playerRatings={savepercentageLeaders} season={selectedSeason}/>
            )}
            <h2 className={styles.statCategory}>Stolen Games</h2>
            {stolenLeaders && (
                <PlayerRatingsTable playerRatings={stolenLeaders} season={selectedSeason}/>
            )}
        </div>
    );
}